let createGetAgencias = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk
}) => (skip = 0, take = 10, Descripcion, Estado) => {
  let request = buildRequestWithAuth("GET");
  return fetch(
    `${environment.authUrl}/controldeusuarios/pedidosaltas?skip=${skip}&take=${take}&Search=${Descripcion}&Estado=${Estado}&format=json`,
    request
  )
    .then(errorUnlessOk)
    .then(response => {
      return response.json();
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

let createGetAgencia = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk
}) => agenciaId => {
  let request = buildRequestWithAuth("GET");
  return fetch(
    `${environment.authUrl}/controldeusuarios/pedidosaltas/${agenciaId}`,
    request
  )
    .then(errorUnlessOk)
    .then(response => {
      return response.json();
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

let createPostResolverConflicto = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk
}) => (agenciaId, comment) => {
  let request = buildRequestWithAuth("POST", { Comentario: comment });
  return fetch(
    `${environment.authUrl}/controldeusuarios/pedidosaltas/${agenciaId}`,
    request
  )
    .then(errorUnlessOk)
    .then(response => {
      return response;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

let createPostSignupAgencia = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk
}) => formData => {
  let request = buildRequestWithAuth("POST", formData);

  return fetch(
    `${environment.authUrl}/controldeusuarios/registroagencia`,
    request
  )
    .then(errorUnlessOk)
    .then(response => {
      return response;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

let createPostSingupAgenciaConfirmacion = ({
  fetch,
  environment,
  errorUnlessOk
}) => token => {
  let request = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ Token: token })
  };

  return fetch(
    `${environment.authUrl}/controldeusuarios/pedidosaltas/confirmacion`,
    request
  )
    .then(errorUnlessOk)
    .then(response => {
      return response.json();
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

let createPostAgenciaUserPassword = ({
  fetch,
  environment,
  errorUnlessOk
}) => formData => {
  let request = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(formData)
  };

  return fetch(
    `${environment.authUrl}/controldeusuarios/pedidosaltas/password`,
    request
  )
    .then(errorUnlessOk)
    .then(response => {
      return response;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export {
  createGetAgencias,
  createGetAgencia,
  createPostResolverConflicto,
  createPostSignupAgencia,
  createPostSingupAgenciaConfirmacion,
  createPostAgenciaUserPassword
};
