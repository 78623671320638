let createGetCreditosVigentes = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
}) => () => {
  let request = buildRequestWithAuth("GET");

  return fetch(
    `${environment.catalogosUrl}/apiclientes/creditos/vigentes`,
    request
  )
    .then(errorUnlessOk)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

let createGetDetalleCredito = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
}) => (operacionId) => {
  let request = buildRequestWithAuth("GET");

  return fetch(
    `${environment.catalogosUrl}/apiclientes/creditos/${operacionId}`,
    request
  )
    .then(errorUnlessOk)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

let createGetCuotasCredito = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
}) => (operacionId) => {
  let request = buildRequestWithAuth("GET");

  return fetch(
    `${environment.catalogosUrl}/creditos/${operacionId}/cuotas`,
    request
  )
    .then(errorUnlessOk)
    .then((response) => response.json())
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

let createGetCuponesCredito = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
  parseError,
}) => (operacionId, CuotasId) => {
  let request = buildRequestWithAuth("GET");
  return fetch(
    `${environment.catalogosUrl}/apiclientes/creditos/${operacionId}/comprobantes/cuponescuota?CuotasId=${CuotasId}`,
    request
  )
    .then(errorUnlessOk)
    .then((response) => response.json())
    .catch((error) => {
      let resultError = parseError(error, "createGetCuponesCredito");
      console.error(resultError);
      throw resultError;
    });
};

let createGetComprobantesPago = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
  parseError,
}) => (operacionId, NroCuotas) => {
  let request = buildRequestWithAuth("GET");
  return fetch(
    `${environment.catalogosUrl}/apiclientes/creditos/${operacionId}/comprobantes/pago?CuotasId=${NroCuotas}`,
    request
  )
    .then(errorUnlessOk)
    .then((response) => response.json())
    .catch((error) => {
      let resultError = parseError(error, "createGetComprobantesPago");
      console.error(resultError);
      throw resultError;
    });
};

let createGetComprobanteCreditoAlDia = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
  parseError,
}) => (operacionId) => {
  let request = buildRequestWithAuth("GET");
  return fetch(
    `${environment.catalogosUrl}/apiclientes/creditos/${operacionId}/comprobantes/creditoaldia`,
    request
  )
    .then(errorUnlessOk)
    .then((response) => response.json())
    .catch((error) => {
      let resultError = parseError(error, "createGetComprobanteCreditoAldia");
      console.error(resultError);
      throw resultError;
    });
};

let createGetCuponPagoSaldo = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
  parseError,
}) => (operacionId) => {
  let request = buildRequestWithAuth("GET");
  return fetch(
    `${environment.catalogosUrl}/apiclientes/creditos/${operacionId}/comprobantes/cuponsaldo`,
    request
  )
    .then(errorUnlessOk)
    .then((response) => response.json())
    .catch((error) => {
      let resultError = parseError(error, "createGetCuponPagoSaldo");
      console.error(resultError);
      throw resultError;
    });
};

let createGetNotaCancelacion = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
  parseError,
}) => (operacionId) => {
  let request = buildRequestWithAuth("GET");
  return fetch(
    `${environment.catalogosUrl}/apiclientes/creditos/${operacionId}/comprobantes/notacancelacion`,
    request
  )
    .then(errorUnlessOk)
    .then((response) => response.json())
    .catch((error) => {
      let resultError = parseError(error, "createGetNotaCancelacion");
      console.error(resultError);
      throw resultError;
    });
};

let createPostInformarPago = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
}) => (data) => {
  let request = buildRequestWithAuth("POST", data);

  return fetch(`${environment.catalogosUrl}/avisospago`, request)
    .then(errorUnlessOk)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export {
  createGetCreditosVigentes,
  createGetDetalleCredito,
  createGetCuotasCredito,
  createGetCuponesCredito,
  createGetComprobantesPago,
  createGetComprobanteCreditoAlDia,
  createGetCuponPagoSaldo,
  createGetNotaCancelacion,
  createPostInformarPago,
};
