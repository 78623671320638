import { createActions, handleActions } from "redux-actions";

export const {
  direccionPersonaSelectProvincia,
  direccionPersonaSelectLocalidad,
  direccionPersonaSelectCodigoPostal,
  direccionPersonaSelectStreet,
  direccionPersonaComplete,
  direccionPersonaClear
} = createActions(
  "DIRECCION_PERSONA_SELECT_PROVINCIA",
  "DIRECCION_PERSONA_SELECT_LOCALIDAD",
  "DIRECCION_PERSONA_SELECT_CODIGO_POSTAL",
  "DIRECCION_PERSONA_SELECT_STREET",
  "DIRECCION_PERSONA_COMPLETE",
  "DIRECCION_PERSONA_CLEAR"
);

const direccionPersonaReducer = handleActions(
  {
    DIRECCION_PERSONA_SELECT_PROVINCIA: (state, action) => {
      return {
        ...state,
        selectedProvince: action.payload.provinciaSelected,
        selectedCity: null,
        selectedPostCode: null,
        streetAddress: "",
        complete: false
      };
    },
    DIRECCION_PERSONA_SELECT_LOCALIDAD: (state, action) => {
      return {
        ...state,
        selectedCity: action.payload.localidadSelected,
        selectedPostCode: null,
        streetAddress: "",
        complete: false
      };
    },
    DIRECCION_PERSONA_SELECT_CODIGO_POSTAL: (state, action) => {
      return {
        ...state,
        selectedPostCode: action.payload.codigoPostalSelected,
        streetAddress: "",
        complete: false
      };
    },
    DIRECCION_PERSONA_SELECT_STREET: (state, action) => {
      return {
        ...state,
        streetAddress: action.payload.streetSelected,
        complete: false
      };
    },
    DIRECCION_PERSONA_COMPLETE: (state, action) => {
      return {
        ...state,
        complete: action.payload.complete
      };
    },
    DIRECCION_PERSONA_CLEAR: (state) => {
      return {
        ...state,
        streetAddress: "",
        selectedProvince: null,
        selectedCity: null,
        selectedPostCode: null,
        complete: false
      };
    }
  },
  {
    streetAddress: "",
    selectedProvince: null,
    selectedCity: null,
    selectedPostCode: null,
    complete: false
  }
);

export default direccionPersonaReducer;
