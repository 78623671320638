import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useFlags } from 'launchdarkly-react-client-sdk';

const Headers = () => {
  const flags = useFlags();
  const [faviconRoute, setFaviconRoute] = useState();
  const [title, setTitle] = useState();
  useEffect(() => {
    if (flags["rebranding"]) {
      setFaviconRoute(`${process.env.PUBLIC_URL}/favicon-prendo.png`);
      setTitle("Prendo App");
    } else {
      setFaviconRoute(`${process.env.PUBLIC_URL}/favicon.png`);
      setTitle("MG App");
    }
  }, [flags]);

  return (
    <Helmet>
      <link id="faviconApple" rel="apple-touch-icon" href={faviconRoute} />
      <link
        id="favicon"
        rel="icon"
        type="image/png"
        sizes="72x72"
        href={faviconRoute}
      />
      <title>{title}</title>
    </Helmet>
  );
};

export default Headers;
