let createGetCanales = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
}) => (tipoCredito) => {
  let request = buildRequestWithAuth("GET");
  const queryParams = tipoCredito ? `&tipoCredito=${tipoCredito}` : "";
  const query = `?format=json${queryParams}`;
  return fetch(
    `${environment.catalogosUrl}/concesionarios/solicitudes${query}`,
    request
  )
    .then(errorUnlessOk)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export { createGetCanales };
