import { createActions, handleActions } from "redux-actions";
export const {
  codeudorSetDni,
  codeudorSetSexo,
  codeudorSetEstadoCivil,
  codeudorSetContacto,
  codeudorSetDireccion,
  codeudorFetchIdentidadInited,
  codeudorFetchIdentidadCompleted,
  codeudorFetchIdentidadFailed,
  codeudorSetActividad,
  codeudorSetRubro,
  codeudorSetAntiguedad,
  codeudorSetIngresos,
  codeudorSetComplete,
  codeudorClear,
} = createActions(
  "CODEUDOR_SET_DNI",
  "CODEUDOR_SET_SEXO",
  "CODEUDOR_SET_ESTADO_CIVIL",
  "CODEUDOR_SET_CONTACTO",
  "CODEUDOR_SET_DIRECCION",
  "CODEUDOR_FETCH_IDENTIDAD_INITED",
  "CODEUDOR_FETCH_IDENTIDAD_COMPLETED",
  "CODEUDOR_FETCH_IDENTIDAD_FAILED",
  "CODEUDOR_SET_ACTIVIDAD",
  "CODEUDOR_SET_RUBRO",
  "CODEUDOR_SET_ANTIGUEDAD",
  "CODEUDOR_SET_INGRESOS",
  "CODEUDOR_SET_COMPLETE",
  "CODEUDOR_CLEAR"
);

export const fetchIdentidad = (dni, sexo) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(codeudorFetchIdentidadInited());
      const response = await services.api.getIdentidad(dni, sexo);
      dispatch(codeudorFetchIdentidadCompleted(response));
    } catch (error) {
      dispatch(services.errorHandler.checkErrorAction(error));
      dispatch(codeudorFetchIdentidadFailed(error));
    }
  };
};
const initialState = {
  isLoading: false,
  error: false,
  complete: false,
  dni: null,
  sexo: null,
  nombres: null,
  apellidos: null,
  fechaNacimiento: null,
  cuil: null,
  contacto: { canContinue: false },
  direccion: { canContinue: false },
  actividad: null,
  rubro: null,
  antiguedad: null,
  ingresos: 0,
};
const AgregarCodeudorTareaReducer = handleActions(
  {
    [codeudorSetDni]: (state, action) => {
      return {
        ...state,
        dni: action.payload,
        complete: false,
      };
    },
    [codeudorSetSexo]: (state, action) => {
      return {
        ...state,
        sexo: action.payload,
        complete: false,
      };
    },
    [codeudorSetEstadoCivil]: (state, action) => {
      return {
        ...state,
        estadoCivil: action.payload,
        complete: false,
      };
    },
    [codeudorSetContacto]: (state, action) => {
      return {
        ...state,
        contacto: action.payload,
      };
    },
    [codeudorSetDireccion]: (state, action) => {
      return {
        ...state,
        direccion: action.payload,
      };
    },
    [codeudorFetchIdentidadInited]: (state) => {
      return {
        ...state,
        isLoading: true,
        nombres: null,
        apellidos: null,
        fechaNacimiento: null,
        cuil: null,
        contacto: { canContinue: false },
        direccion: { canContinue: false },
        actividad: null,
        rubro: null,
        antiguedad: null,
        ingresos: 0,
        complete: false,
      };
    },
    [codeudorFetchIdentidadCompleted]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        identidadError: false,
        complete: false,
        nombres: action.payload.nombres,
        apellidos: action.payload.apellido,
        fechaNacimiento: action.payload.fechaNacimiento,
        cuil: action.payload.cuil,
      };
    },
    [codeudorFetchIdentidadFailed]: (state) => {
      return {
        ...state,
        isLoading: false,
        identidadError: true,
        complete: false,
      };
    },
    [codeudorSetActividad]: (state, action) => {
      return {
        ...state,
        actividad: action.payload,
        rubro: null,
        antiguedad: null,
        complete: false,
      };
    },
    [codeudorSetRubro]: (state, action) => {
      return {
        ...state,
        rubro: action.payload,
        antiguedad: null,
        complete: false,
      };
    },
    [codeudorSetAntiguedad]: (state, action) => {
      return {
        ...state,
        antiguedad: action.payload,
        complete: false,
      };
    },
    [codeudorSetIngresos]: (state, action) => {
      return {
        ...state,
        ingresos: action.payload,
        complete: false,
      };
    },
    [codeudorSetComplete]: (state, action) => {
      return {
        ...state,
        complete: action.payload,
      };
    },
    [codeudorClear]: () => {
      return {
        ...initialState,
      };
    },
  },
  {
    ...initialState,
  }
);
export default AgregarCodeudorTareaReducer;
