import { useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  scrollableBody: {
    backgroundColor: "#f9f7fc",
    position: null,
    overflow: null,
    width: "100%",
  },
  nonScrollableBody: {
    backgroundColor: "#f9f7fc",
    position: "fixed",
    overflow: "hidden",
    width: "100%",
  },
});

// TODO: convert this component to a custom hook, such as useScrollableBody
const InfiniteScroll = () => {
  const classes = useStyles();

  useEffect(() => {
    let lastClassName = document.body.className;

    const intervalRef = setInterval(() => {
      const className = document.body.className;
      if (className !== lastClassName) {
        document.body.className = classes.scrollableBody;
        lastClassName = className;
      }
    }, 10);

    document.body.className = classes.scrollableBody;

    return function cleanup() {
      clearTimeout(intervalRef);

      document.body.className = classes.nonScrollableBody;
    };
  }, [classes.scrollableBody, classes.nonScrollableBody]);

  return "";
};

export default InfiniteScroll;
