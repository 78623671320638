import React from "react";
import PropTypes from "prop-types";
import HttpsRedirect from "react-https-redirect";

class RedirectToHttps extends React.Component {
  renderRedirect = () => <HttpsRedirect>{this.props.children}</HttpsRedirect>;
  renderNormal = () => this.props.children;

  render() {
    const needRedirect = process.env.REACT_APP_REDIRECTTOHTTPS;
    return (
      <React.Fragment>
        {(needRedirect === true || needRedirect === "true") &&
          this.renderRedirect()}
        {(needRedirect === false || needRedirect === "false") &&
          this.renderNormal()}
      </React.Fragment>
    );
  }
}

RedirectToHttps.propTypes = {
  logIn: PropTypes.bool
};

export default RedirectToHttps;
