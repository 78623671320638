import { createActions, handleActions } from "redux-actions";

const {
  business: {
    canales: {
      clearCanales,
      fetchCanalesInited,
      fetchCanalesCompleted,
      fetchCanalesFailed,
    },
  },
} = createActions({
  business: {
    canales: {
      clearCanales: () => ({}),
      fetchCanalesInited: (props) => ({ props }),
      fetchCanalesCompleted: (response) => ({ response }),
      fetchCanalesFailed: (error) => ({ error }),
    },
  },
});

const fetchCanales = (tipoCredito) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchCanalesInited({ tipoCredito }));
      const response = await services.api.getCanales(tipoCredito);
      dispatch(fetchCanalesCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(fetchCanalesFailed(error));
    }
  };
};

const initialState = {
  canales: null,
  isLoading: false,
};

const canalSelectorReducer = handleActions(
  {
    [clearCanales]: () => ({ ...initialState }),
    [fetchCanalesInited]: () => {
      return {
        ...initialState,
        isLoading: true,
      };
    },
    [fetchCanalesCompleted]: (state, action) => {
      const canales = action.payload.response.map((item) => ({
        value: item.key,
        label: item.value,
      }));
      return {
        ...initialState,
        canales: canales,
        isLoading: false,
      };
    },
    [fetchCanalesFailed]: () => {
      return {
        ...initialState,
        isLoading: false,
      };
    },
  },
  initialState
);

export default canalSelectorReducer;
export {
  clearCanales,
  fetchCanalesInited,
  fetchCanalesCompleted,
  fetchCanalesFailed,
  fetchCanales,
};
