let createGetVehiclesMarcas = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
}) => () => {
  let request = buildRequestWithAuth("GET");

  return fetch(`${environment.vehiclesUrl}/marcas`, request)
    .then(errorUnlessOk)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

let createGetVehiclesVersiones = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
}) => (marca, anio) => {
  let request = buildRequestWithAuth("GET");
  return fetch(
    `${environment.vehiclesUrl}/marcas/${encodeURI(marca)}/anios/${encodeURI(
      anio
    )}/versiones`,
    request
  )
    .then(errorUnlessOk)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

let createGetVehiclesDetalle = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
}) => (codia) => {
  let request = buildRequestWithAuth("GET");

  return fetch(
    `${environment.vehiclesUrl}/versiones/${encodeURI(codia)}`,
    request
  )
    .then(errorUnlessOk)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};
let createGetVehiclesPrice = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
}) => (codia, anioDesde, anioHasta) => {
  let request = buildRequestWithAuth("GET");

  return fetch(
    `${environment.vehiclesUrl}/versiones/${encodeURI(
      codia
    )}/precios?desde=${encodeURI(anioDesde)}&hasta=${encodeURI(anioHasta)}`,
    request
  )
    .then(errorUnlessOk)
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      // Multiple values can be returned for the same year.
      // We sort them by descending price.
      return response.sort((a, b) => b.precio - a.precio);
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};
let createGetVehicleReferencePrice = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
}) => (codia, anio) => {
  let request = buildRequestWithAuth("GET");
  const cryptString = "prendobymgu".split("");
  const encryptedCodia = codia
    .toString()
    .split("")
    .map((i) => cryptString[i])
    .join("");
  return fetch(
    `${environment.vehiclesUrl}/valordereferencia/${encodeURI(
      encryptedCodia
    )}?anio=${encodeURI(anio)}`,
    request
  )
    .then(errorUnlessOk)
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      // Multiple values can be returned for the same year.
      // We sort them by descending price.
      return response.sort((a, b) => b.precio - a.precio);
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export {
  createGetVehiclesMarcas,
  createGetVehiclesVersiones,
  createGetVehiclesDetalle,
  createGetVehiclesPrice,
  createGetVehicleReferencePrice,
};
