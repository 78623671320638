import React, { useState } from "react";
import { BottomHeightContext } from "./bottomHeight.context";

export const BottomHeightProvider = ({ children }) => {
  const [bottomHeight, setBottomHeight] = useState(0);
  return (
    <BottomHeightContext.Provider value={{ bottomHeight, setBottomHeight }}>
      {children}
    </BottomHeightContext.Provider>
  );
};
