export const VEHICULOS_MODELOS_INITED = "VEHICULOS_MODELOS_INITED";
export const VEHICULOS_MODELOS_COMPLETED = "VEHICULOS_MODELOS_COMPLETED";
export const VEHICULOS_MODELOS_FAILED = "VEHICULOS_MODELOS_FAILED";

export const fetchVehiculosModelos = (marca, anio) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchVehiculosModelosInited());

      const response = await services.api.getVehiclesVersiones(marca, anio);

      dispatch(fetchVehiculosModelosCompleted(response));
    } catch (error) {
      console.error(error);

      dispatch(fetchVehiculosModelosFailed(error));

      dispatch(services.errorHandler.checkErrorAction(error));
    }
  };
};

const fetchVehiculosModelosInited = () => {
  return {
    type: VEHICULOS_MODELOS_INITED,
  };
};

const fetchVehiculosModelosCompleted = (response) => {
  let modelos = [];

  if (
    typeof response !== "undefined" &&
    response !== null &&
    response.length !== 0
  ) {
    modelos = response.items.map((x) => ({ value: x.codia, label: x.version }));
  }

  return {
    type: VEHICULOS_MODELOS_COMPLETED,
    payload: {
      modelos,
    },
  };
};

const fetchVehiculosModelosFailed = (error) => {
  return {
    type: VEHICULOS_MODELOS_FAILED,
    payload: {
      error,
    },
  };
};
