/* eslint-disable no-extend-native */
String.prototype.toTitleCase = function () {
  if (!this) return "";
  return this.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
};

String.prototype.encodeURI = function () {
  if (!this) return "";
  return encodeURI(this.toString()).replace("(", "%28").replace(")", "%29");
};
export {};
