// don't use checkErrorAction directly, instead call to: dispatch(services.errorHandler.checkErrorAction(error));
export function checkErrorAction(error) {
  return async (dispatch, getState, services) => {
    const errorMessage = services.errorHandler.getErrorMessage(error);
    services.errorHandler.sendError(errorMessage);

    if (services.errorHandler.isTokenExpiredError(error))
      dispatch(services.errorHandler.logOutUser());
  };
}

export function getErrorCode(error) {
  return typeof error.responseStatus !== "undefined" &&
    error.responseStatus !== null &&
    error.responseStatus.errorCode !== "undefined"
    ? error.responseStatus.errorCode
    : "GenericError";
}

export function getErrorMessage(error, genericErrorMessage) {
  const fallbackErrorMessage =
    "Ha ocurrido un error. Por favor, esperá unos segundos e intentá nuevamente. Si el problema persiste, en el menú podés acceder a la opción para contactarte con tu comercial.";

  const errorCode = getErrorCode(error);

  const ErrorMessages = {
    TokenException: () => "Sesión Expirada",
    Unauthorized: () =>
      "Error en el inicio de sesión, verifique usuario y contraseña",
    MaximumLength: () => "Entrada superó tamaño máximo permitido",
    SolicitudRealizada: error => error.responseStatus.message
  };

  const errorMessage = ErrorMessages[errorCode];

  if (errorMessage) return errorMessage(error);
  else return genericErrorMessage ? genericErrorMessage : fallbackErrorMessage;
}

export function isTokenExpiredError(error) {
  const errorCode = getErrorCode(error);

  const TokenExpiredErrors = ["TokenException", "Unauthorized"];

  return TokenExpiredErrors.includes(errorCode);
}
