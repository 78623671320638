import { combineReducers } from "redux";
//BASE REDUCERS
import topBar from "../componentes/topBar/js/reducer";
import authReducer from "componentes/auth/actions";
import nativeMessagesReducer from "../componentes/nativeAppMessaging/js/reducer";
import oldLoginReducer from "../componentes/user/js/reducer";

//OTHER REDUCERS - ToDo: Remove this as components migrate to new structure
import vehiculosModelosReducer from "../componentes/shared/vehiculoModeloSelector/js/reducer";
import codigosPostalesReducer from "../componentes/shared/codigoPostalSelector/js/reducer";
import localidadReducer from "../componentes/shared/localidadSelector/js/reducer";
import direccionPersonaReducer from "../componentes/direccionPersona/actions";
import contactoPersonaReducer from "../componentes/contactoPersona/actions";
import tareasSolicitudReducer from "../componentes/tareasSolicitud/js/actions";
import TareaConyugeReducer from "../componentes/tareasSolicitud/tareaConyuge/actions";
import AgregarCodeudorTareaReducer from "../componentes/tareasSolicitud/tareaCodeudor/agregarCodeudor/actions";
import AgregarConyugeCodeudorTareaReducer from "../componentes/tareasSolicitud/tareaCodeudor/agregarConyugeCodeudor/actions";
import TareaCodeudorReducer from "../componentes/tareasSolicitud/tareaCodeudor/actions";
import TareaTelefonoReducer from "../componentes/tareasSolicitud/tareaTelefono/actions";

import canalSelectorReducer from "components/bussines/canalSelector/actions";
import codigoPostalSelectorReducer from "components/bussines/codigoPostalSelector/actions";
import marcaVehiculoSelectorReducer from "components/bussines/marcaVehiculoSelector/actions";
import modeloVehiculoSelectorReducer from "components/bussines/modeloVehiculoSelector/actions";
import localidadSelectorReducer from "components/bussines/localidadSelector/actions";
import polizaSelectorReducer from "components/bussines/polizaSelector/actions";
import signupProductorReducer from "components/app/authentication/signup/productor/actions";

export const createReducer = (asyncReducers) => {
  return combineReducers({
    authReducer,
    oldLoginReducer,
    topBar,
    nativeMessagesReducer,
    //remove soon

    //OTHER REDUCERS
    vehiculosModelosReducer,
    codigosPostalesReducer,
    localidadReducer,
    direccionPersonaReducer,
    contactoPersonaReducer,
    tareasSolicitudReducer,
    TareaConyugeReducer,
    AgregarCodeudorTareaReducer,
    AgregarConyugeCodeudorTareaReducer,
    TareaCodeudorReducer,
    TareaTelefonoReducer,
    canalSelectorReducer,
    codigoPostalSelectorReducer,
    marcaVehiculoSelectorReducer,
    modeloVehiculoSelectorReducer,
    localidadSelectorReducer,
    polizaSelectorReducer,
    signupProductorReducer,
    //ASYNC REDUCERS
    ...asyncReducers,
  });
};
