import { createActions, handleActions } from "redux-actions";
/* import { fetchTareas } from "../js/actions"; */
export const {
  conyugeCodeudorSetDni,
  conyugeCodeudorSetSexo,
  conyugeCodeudorFetchIdentidadInited,
  conyugeCodeudorFetchIdentidadCompleted,
  conyugeCodeudorFetchIdentidadFailed,
  conyugeCodeudorSetActividad,
  conyugeCodeudorSetRubro,
  conyugeCodeudorSetAntiguedad,
  conyugeCodeudorSetIngresos,
  conyugeCodeudorSetComplete,
  conyugeCodeudorClear,
} = createActions(
  "CONYUGE_CODEUDOR_SET_DNI",
  "CONYUGE_CODEUDOR_SET_SEXO",
  "CONYUGE_CODEUDOR_SET_ESTADO_CIVIL",
  "CONYUGE_CODEUDOR_FETCH_IDENTIDAD_INITED",
  "CONYUGE_CODEUDOR_FETCH_IDENTIDAD_COMPLETED",
  "CONYUGE_CODEUDOR_FETCH_IDENTIDAD_FAILED",
  "CONYUGE_CODEUDOR_SET_ACTIVIDAD",
  "CONYUGE_CODEUDOR_SET_RUBRO",
  "CONYUGE_CODEUDOR_SET_ANTIGUEDAD",
  "CONYUGE_CODEUDOR_SET_INGRESOS",
  "CONYUGE_CODEUDOR_SET_COMPLETE",
  "CONYUGE_CODEUDOR_CLEAR"
);

export const fetchIdentidad = (dni, sexo) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(conyugeCodeudorFetchIdentidadInited());
      const response = await services.api.getIdentidad(dni, sexo);
      dispatch(conyugeCodeudorFetchIdentidadCompleted(response));
    } catch (error) {
      dispatch(services.errorHandler.checkErrorAction(error));
      dispatch(conyugeCodeudorFetchIdentidadFailed(error));
    }
  };
};

const AgregarConyugeCodeudorTareaReducer = handleActions(
  {
    [conyugeCodeudorSetDni]: (state, action) => {
      return {
        ...state,
        documentoConyuge: action.payload,
        complete: false,
      };
    },
    [conyugeCodeudorSetSexo]: (state, action) => {
      return {
        ...state,
        sexoConyuge: action.payload,
        complete: false,
      };
    },
    [conyugeCodeudorFetchIdentidadInited]: (state) => {
      return {
        ...state,
        isLoading: true,
        nombreConyuge: null,
        apellidoConyuge: null,
        fechaNacimiento: null,
        cuil: null,
        actividadDeclarada: null,
        rubroEmpleoPublico: null,
        antiguedad: null,
        ingresos: 0,
        complete: false,
      };
    },
    [conyugeCodeudorFetchIdentidadCompleted]: (state, action) => {
      return {
        ...state,
        nombreConyuge: action.payload.nombres,
        apellidoConyuge: action.payload.apellido,
        fechaNacimiento: action.payload.fechaNacimiento,
        cuil: action.payload.cuil,
        isLoading: false,
        identidadError: false,
        complete: false,
      };
    },
    [conyugeCodeudorFetchIdentidadFailed]: (state) => {
      return {
        ...state,
        isLoading: false,
        identidadError: true,
        complete: false,
      };
    },
    [conyugeCodeudorSetActividad]: (state, action) => {
      return {
        ...state,
        actividadDeclarada: action.payload,
        rubroEmpleoPublico: null,
        antiguedad: null,
        complete: false,
      };
    },
    [conyugeCodeudorSetRubro]: (state, action) => {
      return {
        ...state,
        rubroEmpleoPublico: action.payload,
        antiguedad: null,
        complete: false,
      };
    },
    [conyugeCodeudorSetAntiguedad]: (state, action) => {
      return {
        ...state,
        antiguedad: action.payload,
        complete: false,
      };
    },
    [conyugeCodeudorSetIngresos]: (state, action) => {
      return {
        ...state,
        ingresos: action.payload,
        complete: false,
      };
    },
    [conyugeCodeudorSetComplete]: (state, action) => {
      return { ...state, complete: action.payload };
    },
    [conyugeCodeudorClear]: () => {
      return {
        isLoading: false,
        error: false,
        identidadError: false,
        complete: false,
        documentoConyuge: null,
        sexo: null,
        nombreConyuge: null,
        apellidoConyuge: null,
        fechaNacimiento: null,
        cuil: null,
        actividadDeclarada: null,
        rubroEmpleoPublico: null,
        antiguedad: null,
        ingresos: 0,
      };
    },
  },
  {
    isLoading: false,
    error: false,
    identidadError: false,
    complete: false,
    documentoConyuge: null,
    sexo: null,
    nombreConyuge: null,
    apellidoConyuge: null,
    fechaNacimiento: null,
    cuil: null,
    actividadDeclarada: null,
    rubroEmpleoPublico: null,
    antiguedad: null,
    ingresos: 0,
  }
);
export default AgregarConyugeCodeudorTareaReducer;
