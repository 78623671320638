import { createActions, handleActions } from "redux-actions";

const {
  authentication: {
    signupClienteFinal: {
      submitSignupClienteFinalInited,
      submitSignupClienteFinalCompleted,
      submitSignupClienteFinalFailed,
    },
  },
} = createActions({
  authentication: {
    signupClienteFinal: {
      submitSignupClienteFinalInited: (props) => ({ props }),
      submitSignupClienteFinalCompleted: () => ({}),
      submitSignupClienteFinalFailed: (error) => ({ error }),
    },
  },
});

const initialState = {
  isLoading: false,
};

const submitSignupClienteFinal = (userName, onComplete) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(submitSignupClienteFinalInited({ userName, onComplete }));
      await services.api.postSignupClienteFinal({
        NombreUsuario: userName,
      });
      dispatch(submitSignupClienteFinalCompleted());
      onComplete();
    } catch (error) {
      console.error(error);
      dispatch(submitSignupClienteFinalFailed(error));
    }
  };
};

const signupClienteFinalReducer = handleActions(
  {
    [submitSignupClienteFinalInited]: () => {
      return {
        ...initialState,
        isLoading: true,
      };
    },
    [submitSignupClienteFinalCompleted]: () => {
      return {
        ...initialState,
        isLoading: false,
      };
    },
    [submitSignupClienteFinalFailed]: () => {
      return {
        ...initialState,
        isLoading: false,
      };
    },
  },
  initialState
);

export default signupClienteFinalReducer;
export { submitSignupClienteFinal };
