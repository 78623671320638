import { createActions, handleActions } from "redux-actions";

const {
  business: {
    localidades: {
      fetchLocalidadesInited,
      fetchLocalidadesCompleted,
      fetchLocalidadesFailed,
      clearLocalidades
    },
  },
} = createActions({
  business: {
    localidades: {
      fetchLocalidadesInited: (props) => ({ props }),
      fetchLocalidadesCompleted: (value) => ({ value }),
      fetchLocalidadesFailed: (error) => ({ error }),
      clearLocalidades: () => ({}),
    },
  },
});

const fetchLocalidades = (provinciaId) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchLocalidadesInited({ provinciaId }));
      const response = await services.api.getProvinciasLocalidades(provinciaId);
      const localidades = response.localidades
        .filter((v, i) => response.localidades.indexOf(v) === i)
        .filter((i) => i !== "")
        .map((item) => ({
          value: item,
          label: item,
        }));
      dispatch(fetchLocalidadesCompleted({localidades, provinciaId}));
    } catch (error) {
      console.error(error);
      dispatch(fetchLocalidadesFailed(error));
    }
  };
};

const initialState = {
  localidades: [],
  provinciaId: null,
  isLoading: false,
};

const localidadSelectorReducer = handleActions(
  {
    [clearLocalidades]: () => {
      return {
        ...initialState,
        localidades: [],
      };
    },
    [fetchLocalidadesInited]: () => {
      return {
        ...initialState,
        localidades: [],
        isLoading: true,
      };
    },
    [fetchLocalidadesCompleted]: (state, action) => {
      return {
        ...initialState,
        localidades: action.payload.value.localidades,
        provinciaId: action.payload.value.provinciaId,
        isLoading: false,
      };
    },
    [fetchLocalidadesFailed]: () => {
      return {
        ...initialState,
        isLoading: false,
      };
    },
  },
  initialState
);

export default localidadSelectorReducer;
export {
  fetchLocalidadesInited,
  fetchLocalidadesCompleted,
  fetchLocalidadesFailed,
  fetchLocalidades,
  clearLocalidades,
};
