let createPostRegisterPushToken = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk
}) => data => {
  let request = buildRequestWithAuth("POST", data);

  return fetch(`${environment.catalogosUrl}/push/token`, request)
    .then(errorUnlessOk)
    .then(response => {
      return response;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export { createPostRegisterPushToken };
