import { combineReducers } from "redux";
import signupAgenciaReducer from "./signup/agenciero/actions";
import signupClienteFinalReducer from "./signup/clienteFinal/actions";
import signupProductorReducer from "./signup/productor/actions";
const combinedReducers = combineReducers({
  signupAgenciaReducer,
  signupClienteFinalReducer,
  signupProductorReducer,
});
const authenticationReducers = {
  key: "authenticationReducers",
  value: combinedReducers,
};

export default authenticationReducers;
