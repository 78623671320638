export const HIDE_TOPBAR = "HIDE_TOPBAR";
export const SHOW_TOPBAR = "SHOW_TOPBAR";
export const TOGGLE_OPEN_MAINMENU = "TOGGLE_OPEN_MAINMENU";
export const TOGGLE_OPEN_NOTIFICATIONBAR = "TOGGLE_OPEN_NOTIFICATIONBAR";
export const OPEN_PROFILEMENU = "OPEN_PROFILEMENU";
export const OPEN_MAINMENU = "OPEN_MAINMENU";
export const MAINMENU_SET = "MAINMENU_SET";

export const hideTopBar = backUrl => ({
  type: HIDE_TOPBAR,
  payload: { backUrl }
});

export const showTopBar = () => ({
  type: SHOW_TOPBAR,
  payload: {}
});

export const toggleOpenMainMenu = () => ({
  type: TOGGLE_OPEN_MAINMENU,
  payload: {}
});

export const toggleOpenNotificationBar = isOpen => ({
  type: TOGGLE_OPEN_NOTIFICATIONBAR,
  payload: {
    open: isOpen
  }
});

export const openMainMenu = open => ({
  type: OPEN_MAINMENU,
  payload: {
    open: open
  }
});

export const openProfileMenu = payload => ({
  type: OPEN_PROFILEMENU,
  payload: {
    open: payload.open,
    anchorEl: payload.anchorEl
  }
});

export const setTopBar = (title, backUrl, menuItem) => ({
  type: MAINMENU_SET,
  payload: {
    title: title,
    backUrl: backUrl,
    menuItem: menuItem
  }
});
