import EmailValidator from "email-validator";
import isNullOrEmpty from "../../../libs/isNullOrEmpty";

export const validateEmail = (email, isRequired) => {
  let emailValidation = null;
  const realRequired = isRequired;
  const validationSuccess = {
    valid: true,
    error: "",
    empty: isNullOrEmpty(email)
  };

  if (isNullOrEmpty(email) && realRequired) {
    emailValidation = {
      valid: false,
      empty: isNullOrEmpty(email),
      error: "Email requerido"
    };
  } else if (!isNullOrEmpty(email) && !EmailValidator.validate(email)) {
    emailValidation = {
      valid: false,
      empty: isNullOrEmpty(email),
      error: "La dirección de correo electrónico no es válida"
    };
  }

  return emailValidation ? emailValidation : validationSuccess;
};
