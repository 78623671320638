import { createActions, handleActions } from "redux-actions";
import { TODAS_COBERTURAS } from "constants/tipoPoliza";

const {
  business: {
    polizaSelector: {
      clearPolizaSelector,
      setFiltroTipoCobertura,
      setMinPrice,
      setMaxPrice,
      setFiltroMinPrice,
      setFiltroMaxPrice,
      setIsFiltering,
    },
  },
} = createActions({
  business: {
    polizaSelector: {
      clearPolizaSelector: () => ({}),
      setFiltroTipoCobertura: (value) => ({ value }),
      setMinPrice: (value) => ({ value }),
      setMaxPrice: (value) => ({ value }),
      setFiltroMinPrice: (value) => ({ value }),
      setFiltroMaxPrice: (value) => ({ value }),
      setIsFiltering: (value) => ({ value }),
    },
  },
});

const initialState = {
  minPrice: null,
  maxPrice: null,
  filtroTipoCobertura: TODAS_COBERTURAS,
  filtroMinPrice: null,
  filtroMaxPrice: null,
  isLoading: false,
  isFiltering: false,
};

const polizaSelectorReducer = handleActions(
  {
    [clearPolizaSelector]: () => ({ ...initialState }),
    [setFiltroTipoCobertura]: (state, action) => ({
      ...initialState,
      filtroTipoCobertura: action.payload.value,
      isFiltering: true,
    }),
    [setMinPrice]: (state, action) => ({
      ...state,
      minPrice: action.payload.value,
    }),
    [setMaxPrice]: (state, action) => {
      return {
        ...state,
        maxPrice: action.payload.value,
      };
    },
    [setFiltroMinPrice]: (state, action) => {
      return {
        ...state,
        filtroMinPrice: action.payload.value,
      };
    },
    [setFiltroMaxPrice]: (state, action) => {
      return {
        ...state,
        filtroMaxPrice: action.payload.value,
      };
    },
    [setIsFiltering]: (state, action) => {
      return {
        ...state,
        isFiltering: action.payload.value,
      };
    },
  },
  initialState
);

export default polizaSelectorReducer;
export {
  clearPolizaSelector,
  setFiltroTipoCobertura,
  setMinPrice,
  setMaxPrice,
  setFiltroMinPrice,
  setFiltroMaxPrice,
  setIsFiltering,
};
