let createGetTermsAndConditions = ({
  fetch
}) => () => {
  //  let request = buildRequestWithAuth("GET");

  return (
    fetch("https://mg-group.com.ar/mg-app-content/mg-app-tyc.html")
      //.then(errorUnlessOk)
      .then(response => {
        return response.text();
      })
      .catch(error => {
        console.error(error);
        throw error;
      })
  );
};

export { createGetTermsAndConditions };
