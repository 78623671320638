import { createActions, handleActions } from "redux-actions";

const {
  authentication: {
    signupAgencia: {
      clear,
      setCuiTitular,
      clearCuiTitular,
      setNombreAgencia,
      clearNombreAgencia,
      setNombreTitular,
      clearNombreTitular,
      setProvinciaAgencia,
      clearProvinciaAgencia,
      setLocalidadAgencia,
      clearLocalidadAgencia,
      setCodigoPostalAgencia,
      clearCodigoPostalAgencia,
      setDireccionAgencia,
      clearDireccionAgencia,
      setTipoAgencia,
      clearTipoAgencia,
      setTipoVehiculo,
      clearTipoVehiculo,
      setNombreContacto,
      clearNombreContacto,
      setTelefonoContacto,
      clearTelefonoContacto,
      submitSignupAgenciaInited,
      submitSignupAgenciaCompleted,
      submitSignupAgenciaFailed,
      setTerminosYCondiciones,
      clearTerminosYCondiciones,
    },
  },
} = createActions({
  authentication: {
    signupAgencia: {
      clear: () => ({}),
      setCuiTitular: (value) => ({ value }),
      clearCuiTitular: () => ({}),
      setNombreAgencia: (value) => ({ value }),
      clearNombreAgencia: () => ({}),
      setNombreTitular: (value) => ({ value }),
      clearNombreTitular: () => ({}),
      setProvinciaAgencia: (value) => ({ value }),
      clearProvinciaAgencia: () => ({}),
      setLocalidadAgencia: (value) => ({ value }),
      clearLocalidadAgencia: () => ({}),
      setCodigoPostalAgencia: (value) => ({ value }),
      clearCodigoPostalAgencia: () => ({}),
      setDireccionAgencia: (value) => ({ value }),
      clearDireccionAgencia: () => ({}),
      setTipoAgencia: (value) => ({ value }),
      clearTipoAgencia: () => ({}),
      setTipoVehiculo: (value) => ({ value }),
      clearTipoVehiculo: () => ({}),
      setNombreContacto: (value) => ({ value }),
      clearNombreContacto: () => ({}),
      setTelefonoContacto: (value) => ({ value }),
      clearTelefonoContacto: () => ({}),
      submitSignupAgenciaInited: (props) => ({ props }),
      submitSignupAgenciaCompleted: () => ({}),
      submitSignupAgenciaFailed: (error) => ({ error }),
      setTerminosYCondiciones: () => ({}),
      clearTerminosYCondiciones: () => ({}),
    },
  },
});

const initialState = {
  cuiTitular: null,
  nombreAgencia: null,
  nombreTitular: null,
  provinciaAgencia: null,
  localidadAgencia: null,
  codigoPostalAgencia: null,
  direccionAgencia: null,
  tipoAgencia: null,
  tipoVehiculo: null,
  nombreContacto: null,
  telefonoContacto: null,
  terminosYCondiciones: null,
  isForward: true,
  isLoading: false,
};

const submitSignupAgencia = (data) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(submitSignupAgenciaInited({ data }));
      await services.api.postSignupAgencia(data);
      dispatch(submitSignupAgenciaCompleted());
    } catch (error) {
      console.error(error);
      dispatch(submitSignupAgenciaFailed(error));
    }
  };
};

const signupAgenciaReducer = handleActions(
  {
    [clear]: () => {
      return {
        ...initialState,
        isForward: true,
      };
    },
    [setCuiTitular]: (state, action) => {
      return {
        ...state,
        cuiTitular: action.payload.value,
        isForward: true,
      };
    },
    [clearCuiTitular]: (state) => {
      return {
        ...state,
        cuiTitular: null,
        isForward: false,
      };
    },
    [setNombreAgencia]: (state, action) => {
      return {
        ...state,
        nombreAgencia: action.payload.value,
        isForward: true,
      };
    },
    [clearNombreAgencia]: (state) => {
      return {
        ...state,
        nombreAgencia: null,
        isForward: false,
      };
    },
    [setNombreTitular]: (state, action) => {
      return {
        ...state,
        nombreTitular: action.payload.value,
        isForward: true,
      };
    },
    [clearNombreTitular]: (state) => {
      return {
        ...state,
        nombreTitular: null,
        isForward: false,
      };
    },
    [setProvinciaAgencia]: (state, action) => {
      const localidad = action.payload.value === 1 ? "CIUDAD AUTONOMA DE BS. AS." : null;
      return {
        ...state,
        provinciaAgencia: action.payload.value,
        localidadAgencia: localidad,
        isForward: true,
      };
    },
    [clearProvinciaAgencia]: (state) => {
      return {
        ...state,
        provinciaAgencia: null,
        isForward: false,
      };
    },
    [setLocalidadAgencia]: (state, action) => {
      return {
        ...state,
        localidadAgencia: action.payload.value,
        isForward: true,
      };
    },
    [clearLocalidadAgencia]: (state) => {
      return {
        ...state,
        localidadAgencia: null,
        isForward: false,
      };
    },
    [setCodigoPostalAgencia]: (state, action) => {
      return {
        ...state,
        codigoPostalAgencia: action.payload.value,
        isForward: true,
      };
    },
    [clearCodigoPostalAgencia]: (state) => {
      return {
        ...state,
        codigoPostalAgencia: null,
        isForward: false,
      };
    },
    [setDireccionAgencia]: (state, action) => {
      return {
        ...state,
        direccionAgencia: action.payload.value,
        isForward: true,
      };
    },
    [clearDireccionAgencia]: (state) => {
      return {
        ...state,
        direccionAgencia: null,
        isForward: false,
      };
    },
    [setTipoAgencia]: (state, action) => {
      return {
        ...state,
        tipoAgencia: action.payload.value,
        isForward: true,
      };
    },
    [clearTipoAgencia]: (state) => {
      return {
        ...state,
        tipoAgencia: null,
        isForward: false,
      };
    },
    [setTipoVehiculo]: (state, action) => {
      return {
        ...state,
        tipoVehiculo: action.payload.value,
        isForward: true,
      };
    },
    [clearTipoVehiculo]: (state) => {
      return {
        ...state,
        tipoVehiculo: null,
        isForward: false,
      };
    },
    [setNombreContacto]: (state, action) => {
      return {
        ...state,
        nombreContacto: action.payload.value,
        isForward: true,
      };
    },
    [clearNombreContacto]: (state) => {
      return {
        ...state,
        nombreContacto: null,
        isForward: false,
      };
    },
    [setTelefonoContacto]: (state, action) => {
      return {
        ...state,
        telefonoContacto: action.payload.value,
        isForward: true,
      };
    },
    [clearTelefonoContacto]: (state) => {
      return {
        ...state,
        telefonoContacto: null,
        isForward: false,
      };
    },
    [setTerminosYCondiciones]: (state) => {
      return {
        ...state,
        terminosYCondiciones: true,
        isForward: true,
      };
    },
    [clearTerminosYCondiciones]: (state) => {
      return {
        ...state,
        terminosYCondiciones: false,
        isForward: false,
      };
    },
  },
  initialState
);

export default signupAgenciaReducer;
export {
  clear,
  setCuiTitular,
  clearCuiTitular,
  setNombreAgencia,
  clearNombreAgencia,
  setNombreTitular,
  clearNombreTitular,
  setProvinciaAgencia,
  clearProvinciaAgencia,
  setLocalidadAgencia,
  clearLocalidadAgencia,
  setCodigoPostalAgencia,
  clearCodigoPostalAgencia,
  setDireccionAgencia,
  clearDireccionAgencia,
  setTipoAgencia,
  clearTipoAgencia,
  setTipoVehiculo,
  clearTipoVehiculo,
  setNombreContacto,
  clearNombreContacto,
  setTelefonoContacto,
  clearTelefonoContacto,
  setTerminosYCondiciones,
  clearTerminosYCondiciones,
  submitSignupAgencia,
};
