import { createActions, handleActions } from "redux-actions";
import { tareaEnviadaParaAnalizar } from "../js/actions";
export const {
  tareaConyugeSetDni,
  tareaConyugeSetSexo,
  tareaConyugeFetchIdentidadInited,
  tareaConyugeFetchIdentidadCompleted,
  tareaConyugeFetchIdentidadFailed,
  tareaConyugeSetActividad,
  tareaConyugeSetRubro,
  tareaConyugeSetAntiguedad,
  tareaConyugeSetIngresos,
  tareaConyugeSetComplete,
  tareaConyugeClear,
  submitTareaConyugeInited,
  submitTareaConyugeCompleted,
  submitTareaConyugeFailed,
} = createActions(
  "TAREA_CONYUGE_SET_DNI",
  "TAREA_CONYUGE_SET_SEXO",
  "TAREA_CONYUGE_FETCH_IDENTIDAD_INITED",
  "TAREA_CONYUGE_FETCH_IDENTIDAD_COMPLETED",
  "TAREA_CONYUGE_FETCH_IDENTIDAD_FAILED",
  "TAREA_CONYUGE_SET_ACTIVIDAD",
  "TAREA_CONYUGE_SET_RUBRO",
  "TAREA_CONYUGE_SET_ANTIGUEDAD",
  "TAREA_CONYUGE_SET_INGRESOS",
  "TAREA_CONYUGE_SET_COMPLETE",
  "TAREA_CONYUGE_CLEAR",
  "SUBMIT_TAREA_CONYUGE_INITED",
  "SUBMIT_TAREA_CONYUGE_COMPLETED",
  "SUBMIT_TAREA_CONYUGE_FAILED"
);

export const fetchIdentidad = (dni, sexo) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(tareaConyugeFetchIdentidadInited());
      const response = await services.api.getIdentidad(dni, sexo);
      dispatch(tareaConyugeFetchIdentidadCompleted(response));
    } catch (error) {
      dispatch(services.errorHandler.checkErrorAction(error));
      dispatch(tareaConyugeFetchIdentidadFailed(error));
    }
  };
};

export const submitTareaConyuge = (solicitudId, tarea, comentario) => {
  return async (dispatch, getState, services) => {
    try {
      const conyuge = getState().TareaConyugeReducer;
      let tareaUpdated = JSON.parse(JSON.stringify(tarea));

      let request = "";
      if (conyuge && conyuge.complete) {
        let data = JSON.stringify({
          Nombre: conyuge.nombres + " " + conyuge.apellidos,
          Documento: conyuge.dni,
          ActividadDeclarada: conyuge.actividad && conyuge.actividad.label,
          FechaNacimiento: conyuge.fechaNacimiento,
          Ingreso: conyuge.ingresos,
        });
        request = {
          Sequence: tarea.sequence,
          Comentario: comentario,
          data: data,
          conyuge: {
            nombreConyuge: conyuge.nombres,
            apellidoConyuge: conyuge.apellidos,
            DocumentoConyuge: conyuge.dni,
            SexoConyuge: conyuge.sexo && conyuge.sexo.value,
            Cuil: conyuge.cuil,
            ActividadDeclarada: conyuge.actividad && conyuge.actividad.value,
            Antiguedad: conyuge.antiguedad && conyuge.antiguedad.value,
            FechaNacimiento: conyuge.fechaNacimiento,
            Ingreso:
              conyuge.ingresos &&
              conyuge.ingresos.replace("$", "").replace(".", ""),
          },
        };
        tareaUpdated.data = data;
      } else {
        request = {
          sequence: tarea.sequence,
          comentario: comentario,
        };
      }
      //Actualizo el contenido de la tarea
      if (comentario) {
        tareaUpdated.log.push({ comentario: comentario, usrExterno: true });
      }
      tareaUpdated.estado = "Para_Analizar";

      dispatch(submitTareaConyugeInited());
      await services.api.postTareaConyuge(solicitudId, request);
      dispatch(tareaEnviadaParaAnalizar(tareaUpdated));
      dispatch(submitTareaConyugeCompleted());
    } catch (error) {
      console.error(error);
      dispatch(submitTareaConyugeFailed(error));
    }
  };
};

const TareaConyugeReducer = handleActions(
  {
    [tareaConyugeSetDni]: (state, action) => {
      return {
        ...state,
        dni: action.payload,
        complete: false,
      };
    },
    [tareaConyugeSetSexo]: (state, action) => {
      return {
        ...state,
        sexo: action.payload,
        complete: false,
      };
    },
    [tareaConyugeFetchIdentidadInited]: (state) => {
      return {
        ...state,
        isLoading: true,
        nombres: null,
        apellidos: null,
        fechaNacimiento: null,
        cuil: null,
        actividad: null,
        rubro: null,
        antiguedad: null,
        ingresos: 0,
        complete: false,
      };
    },
    [tareaConyugeFetchIdentidadCompleted]: (state, action) => {
      return {
        ...state,
        nombres: action.payload.nombres,
        apellidos: action.payload.apellido,
        fechaNacimiento: action.payload.fechaNacimiento,
        cuil: action.payload.cuil,
        isLoading: false,
        identidadError: false,
        complete: false,
      };
    },
    [tareaConyugeFetchIdentidadFailed]: (state) => {
      return {
        ...state,
        isLoading: false,
        identidadError: true,
        complete: false,
      };
    },
    [tareaConyugeSetActividad]: (state, action) => {
      return {
        ...state,
        actividad: action.payload,
        rubro: null,
        antiguedad: null,
        complete: false,
      };
    },
    [tareaConyugeSetRubro]: (state, action) => {
      return {
        ...state,
        rubro: action.payload,
        antiguedad: null,
        complete: false,
      };
    },
    [tareaConyugeSetAntiguedad]: (state, action) => {
      return {
        ...state,
        antiguedad: action.payload,
        complete: false,
      };
    },
    [tareaConyugeSetIngresos]: (state, action) => {
      return {
        ...state,
        ingresos: action.payload,
        complete: false,
      };
    },
    [tareaConyugeSetComplete]: (state, action) => {
      return { ...state, complete: action.payload };
    },
    [tareaConyugeClear]: () => {
      return {
        isLoading: false,
        identidadError: false,
        complete: false,
        dni: null,
        sexo: null,
        nombres: null,
        apellidos: null,
        fechaNacimiento: null,
        cuil: null,
        actividad: null,
        rubro: null,
        antiguedad: null,
        ingresos: 0,
      };
    },
    [submitTareaConyugeInited]: (state) => {
      return { ...state, submitIsLoading: true };
    },
    [submitTareaConyugeCompleted]: (state) => {
      return { ...state, submitIsLoading: false };
    },
    [submitTareaConyugeFailed]: (state) => {
      return { ...state, submitIsLoading: false, error: true };
    },
  },
  {
    isLoading: false,
    submitIsLoading: false,
    error: false,
    identidadError: false,
    complete: false,
    dni: null,
    sexo: null,
    nombres: null,
    apellidos: null,
    fechaNacimiento: null,
    cuil: null,
    actividad: null,
    rubro: null,
    antiguedad: null,
    ingresos: 0,
  }
);

export default TareaConyugeReducer;
