const provinciasArgentinas = [
  {
    key: 1,
    value: "Capital Federal"
  },
  {
    key: 2,
    value: "Buenos Aires"
  },
  {
    key: 3,
    value: "Córdoba"
  },
  {
    key: 4,
    value: "Tucuman"
  },
  {
    key: 5,
    value: "Misiones"
  },
  {
    key: 6,
    value: "Corrientes"
  },
  {
    key: 7,
    value: "Entre Ríos"
  },
  {
    key: 8,
    value: "Chaco"
  },
  {
    key: 9,
    value: "Formosa"
  },
  {
    key: 10,
    value: "Jujuy"
  },
  {
    key: 11,
    value: "Salta"
  },
  {
    key: 12,
    value: "Santiago del Estero"
  },
  {
    key: 13,
    value: "La Pampa"
  },
  {
    key: 14,
    value: "Catamarca"
  },
  {
    key: 15,
    value: "La Rioja"
  },
  {
    key: 16,
    value: "San Juan"
  },
  {
    key: 17,
    value: "Mendoza"
  },
  {
    key: 18,
    value: "San Luis"
  },
  {
    key: 20,
    value: "Santa Fe"
  },
  {
    key: 21,
    value: "Neuquén"
  },
  {
    key: 22,
    value: "Río Negro"
  },
  {
    key: 23,
    value: "Chubut"
  },
  {
    key: 24,
    value: "Santa Cruz"
  },
  {
    key: 25,
    value: "Tierra del Fuego"
  }
];

let getProvinciasArgentinas = () => {
  return Promise.resolve(provinciasArgentinas);
};

let createGetProvinciasLocalidades = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk
}) => provinciaId => {
  let request = buildRequestWithAuth("GET");

  return fetch(
    `${environment.catalogosUrl}/provincias/${encodeURI(
      provinciaId
    )}/localidades?format=json`,
    request
  )
    .then(errorUnlessOk)
    .then(response => {
      return response.json();
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

let createGetProvinciasCodigosPostales = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk
}) => (provinciaId, localidad) => {
  let request = buildRequestWithAuth("GET");

  let url = `${environment.catalogosUrl}/provincias/${encodeURI(
    provinciaId
  )}/codigospostales`;

  url = localidad ? `${url}?localidad=${encodeURI(localidad)}` : url;

  return fetch(url, request)
    .then(errorUnlessOk)
    .then(response => {
      return response.json();
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export {
  createGetProvinciasLocalidades,
  createGetProvinciasCodigosPostales,
  getProvinciasArgentinas,
  provinciasArgentinas
};
