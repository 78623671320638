import React, { useState } from "react";
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";

import pxToRem from "libs/pxToRem";
export const ESituacionOperacion = {
  //solicitudes
  Carga_Incompleta: 1,
  Informes_Pendientes: 4,
  Rechazada: 6,
  Rechazada_Definitivamente: 7,
  En_Analisis: 92,
  No_Apto_Habilitado: 99,
  Aprobada: 18,
  Confeccion_Solicitada: 80,
  Prenda_Confeccionada: 16,
  Liquidacion_solicitada: 81,
  Desistida: 19,
  Desistida_por_tiempo: 20,
  Liquidada: 8,
  En_Espera_Informes_Riesgo_Vehiculo: 83,
  Con_Vehiculo_Aprobado: 84,

  //creditos
  Cancelado: 11,
  Cancelado_Anticipadamente: 13,
  Cancelado_Anticipadamente_Judicial: 25,
  Siniestro_en_tramite: 50,
  Siniestro_pendiente_desembolso: 51,
  Dacion_En_Pago: 59,
  Finalizado: 60,
  Plazo_completado: 70,

  //Otras entidades
  Para_ofrecer_otras_entidades: 61,
  Cargada_otras_entidades: 62,
  Rechazada_otras_entidades: 63,
  Aprobada_otras_entidades: 64,
  Para_prendar_otras_entidades: 65,
  En_espera_liquidacion_otras_entidades: 66,
  Liquidada_otras_entidades: 67,
};

//Cuando salga a prod Yen, los filtros (estado-situaciones) tienen que coincidir con los de los labels (getSituacionStyle)
//Ya que van a haber situaciones nuevas que deben ser desglosadas.
export const creditosFilters = [
  {
    estado: "Todas",
    situaciones: [],
  },
  {
    estado: "En análisis",
    situaciones: [
      ESituacionOperacion.En_Analisis,
      ESituacionOperacion.Informes_Pendientes,
    ],
  },
  {
    estado: "Rechazadas",
    situaciones: [
      ESituacionOperacion.Rechazada,
      ESituacionOperacion.Rechazada_Definitivamente,
    ],
  },
  {
    estado: "Desistidas",
    situaciones: [
      ESituacionOperacion.Desistida,
      ESituacionOperacion.Desistida_por_tiempo,
    ],
  },
  {
    estado: "Aprobadas",
    situaciones: [
      ESituacionOperacion.Aprobada,
      ESituacionOperacion.Confeccion_Solicitada,
      ESituacionOperacion.Prenda_Confeccionada,
      ESituacionOperacion.Liquidacion_Solicitada,
    ],
  },
  {
    estado: "Incompletas",
    situaciones: [
      ESituacionOperacion.Carga_Incompleta,
      ESituacionOperacion.No_Apto_Habilitado,
    ],
  },
  {
    estado: "Sin prendas",
    situaciones: [ESituacionOperacion.Liquidada],
  },
];

const FiltroCreditosEstado = ({ filter, onChange }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (filtro) => {
    onChange(filtro);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        onClick={handleClickListItem}
        style={{
          display: "flex",
          cursor: "pointer",
        }}
      >
        <Typography
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            lineHeight: 1.2,
            color: "#663EA8",
            opacity: 0.7,
            fontSize: pxToRem(15),
          }}
        >
          {filter.estado}
          <ArrowDownIcon color="primary" />
        </Typography>
      </div>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: classes.paper, list: classes.list }}
      >
        {creditosFilters.map((f) => (
          <MenuItem
            key={f.estado}
            selected={f === filter.situaciones}
            onClick={() => handleMenuItemClick(f)}
            classes={{ root: classes.root, selected: classes.selected }}
          >
            {f.estado}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default FiltroCreditosEstado;

const useStyles = makeStyles({
  paper: {
    boxShadow: "0px 0px 6px rgba(162, 138, 201, 0.25)",
    borderRadius: pxToRem(8),
    padding: pxToRem(4),
  },
  list: { padding: 0 },
  root: {
    borderRadius: pxToRem(4),
    fontSize: pxToRem(15),
    lineHeight: pxToRem(24),
    fontWeight: "bold",
    color: "#9294AA",
    "&:hover": {
      backgroundColor: "#EFEBF6",
    },
  },
  selected: {
    color: "#7644C9",
    backgroundColor: "#EFEBF6 !important",
  },
});
