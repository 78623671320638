import React from "react";
import Button from '@material-ui/core/Button';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    addLink: {
        width: 279,
        height: [[42], "!important"],
        margin: [["6px 0px"], "!important"],
        border: [["solid 1px " + theme.palette.primary.light], "!important"],
        color: [[theme.palette.primary.light], "!important"],
        textTransform: [["none"], "!important"],
        letterSpacing: [["normal"], "!important"],
        lineHeight: [["1.38"], "!important"],
        fontFamily: [[theme.typography.fontFamily], "!important"],
        fontSize: [["0.8125rem"], "!important"],
        fontWeight: [["bold"], "!important"],
        "&:hover": {
          background: [[theme.palette.primary.darkEmphasized], "!important"],
          color: [[theme.palette.custom.white], "!important"],
          borderColor: [[theme.palette.primary.darkEmphasized], "!important"],
        },
        "&:focus": {
          background: [[theme.palette.primary.light], "!important"],
          color: [[theme.palette.custom.white], "!important"],
          borderColor: [[theme.palette.primary.light], "!important"],
        },
        "&:disabled": {
          borderColor: [[theme.palette.custom.disabledBtnLinkColor], "!important"],
          color: ["#C4C4C4", "!important"],
        },
      }}),
    );

export default function OutlinedButton(props) {
    const classes = useStyles();
    const { labelText, ...otherProps } = props;

    return(
        <Button className={classes.addLink} {...otherProps}>
            {labelText}
        </Button>
    );
}
