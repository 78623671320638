const fetchRetry = require('fetch-retry')(global.fetch);

let createPostCapturaAutoAsync = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
}) => (key, image) => {
  let request = buildRequestWithAuth("GET");

  return fetch(`${environment.presignerUrl}/sign?key=autos/${key}.jpg`, request)
    .then(errorUnlessOk)
    .then((res) => res.json())
    .then((json) => {
      var xhr = new XMLHttpRequest();
      xhr.open("PUT", json.url);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.onload = () => {
        if (xhr.status === 200) {
          console.log("Image uploaded!"); // TODO: no habria que mostrar feedback al usuario??
        }
      };
      xhr.onerror = (e) => {
        console.log(e); // TODO: no habria que mostrar feedback al usuario??
      };
      xhr.send(image);
    })
    .catch((err) => {
      console.log(err); // TODO: no habria que mostrar feedback al usuario??
    });
};

let createGetCapturaAutoResult = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
}) => (key) => {
  let request = buildRequestWithAuth("GET");

  return fetch(
    `${environment.presignerUrl}/sign?key=patentes/${key}.json&verb=get`,
    request
  )
    .then(errorUnlessOk)
    .then((res) => res.json())
    .then((json) => {
      return fetchRetry(json.url, {
        retryOn: [404],
        retries: 5,
        retryDelay: 1000,
      })
        .then(function(response) {
          return response.text();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

function postFile(url, formData, localRepository) {
  return new Promise(function(resolve, reject) {
    let xhr = new XMLHttpRequest();
    xhr.open("POST", url);
    xhr.setRequestHeader(
      "Authorization",
      "Bearer " + localRepository.accessToken.get()
    );
    xhr.onload = function() {
      if (this.status >= 200 && this.status < 300) {
        resolve(JSON.parse(xhr.response));
      } else {
        reject({
          status: this.status,
          statusText: xhr.statusText,
        });
      }
    };
    xhr.onerror = function() {
      reject({
        status: this.status,
        statusText: xhr.statusText,
      });
    };
    xhr.send(formData);
  });
}

let createPostCapturaAuto = ({ environment, localRepository }) => (
  key,
  image
) => {
  const formData = new FormData();
  formData.append(key, image);

  return postFile(
    `${environment.originacionUrl}/autos/captura`,
    formData,
    localRepository
  );
};

export {
  createPostCapturaAuto,
  createPostCapturaAutoAsync,
  createGetCapturaAutoResult,
};
