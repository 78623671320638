let createPostSolicitudNueva =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (data) => {
    let request = buildRequestWithAuth("POST", data);

    return fetch(`${environment.originacionUrl}/solicitudes/nueva`, request)
      .then(errorUnlessOk)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (typeof json.id === "undefined" || isNaN(json.id))
          throw new Error("La solicitud no fue creada correctamente");

        return json;
      })
      .catch((error) => {
        let resultError = parseError(error, "createPostSolicitudNueva");
        console.error(resultError);
        throw resultError;
      });
  };

let createPostContinuarSolicitud =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (data) => {
    let request = buildRequestWithAuth("POST", data);

    return fetch(
      `${environment.originacionUrl}/solicitudes/${data.Id}/continuacioncarga`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let resultError = parseError(error, "createPostContinuarSolicitud");
        console.error(resultError);
        throw resultError;
      });
  };

let createPostSolicitudIngresos =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (data) => {
    let request = buildRequestWithAuth("POST", data);
    return fetch(
      `${environment.originacionUrl}/solicitudes/${data.Id}/ingresos`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let resultError = parseError(error, "createPostSolicitudIngresos");
        console.error(resultError);
        throw resultError;
      });
  };

let createPostSolicitudDomicilioTitular =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (data) => {
    let request = buildRequestWithAuth("POST", data);

    return fetch(
      `${environment.originacionUrl}/solicitudes/${data.Id}/domicilio`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let resultError = parseError(
          error,
          "createPostSolicitudDomicilioTitular"
        );
        console.error(resultError);
        throw resultError;
      });
  };

let createGetConfiguracionContactos =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (solicitudId) => {
    let request = buildRequestWithAuth("GET");

    return fetch(
      `${environment.originacionUrl}/solicitudes/${solicitudId}/contactos/configuracion`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        let resultError = parseError(error, "createGetConfiguracionContactos");
        console.error(resultError);
        throw resultError;
      });
  };

let createPostSolicitudAuto =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk }) =>
  (data) => {
    let request = buildRequestWithAuth("POST", data);

    return fetch(
      `${environment.originacionUrl}/solicitudes/${data.Id}/vehiculo`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };

let createPostSolicitudPreferenciaVehiculo =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (data) => {
    let request = buildRequestWithAuth("POST", data);

    return fetch(
      `${environment.originacionUrl}/solicitudes/${data.Id}/preferenciavehiculo`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let resultError = parseError(
          error,
          "createPostSolicitudPreferenciaVehiculo"
        );
        console.error(resultError);
        throw resultError;
      });
  };

let createPostSolicitudImporte =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk }) =>
  (data) => {
    let request = buildRequestWithAuth("POST", data);

    return fetch(
      `${environment.originacionUrl}/solicitudes/${data.Id}/importesolicitado`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };

let createPostSolicitudSeguroCotizacion =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (data) => {
    let request = buildRequestWithAuth("POST", data);

    return fetch(
      `${environment.originacionUrl}/solicitudes/${data.Id}/cotizacion`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let resultError = parseError(
          error,
          "createPostSolicitudSeguroCotizacion"
        );
        console.error(resultError);
        throw resultError;
      });
  };

let createGetSolicitudSegurosCotizaciones =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (solicitudId) => {
    let request = buildRequestWithAuth("GET");

    return fetch(
      `${environment.originacionUrl}/solicitudes/${solicitudId}/cotizaciones`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        let resultError = parseError(
          error,
          "createGetSolicitudSegurosCotizaciones"
        );
        console.error(resultError);
        throw resultError;
      });
  };

let createGetSolicitudSegurosInicio =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk }) =>
  (solicitudId) => {
    let request = buildRequestWithAuth("GET");

    return fetch(
      `${environment.originacionUrl}/solicitudes/${solicitudId}/iniciocotizacion`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };

let createGetSolicitudSegurosResult =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk }) =>
  (solicitudId, cotizacionId) => {
    let request = buildRequestWithAuth("GET");

    return fetch(
      `${environment.originacionUrl}/solicitudes/${solicitudId}/resultadocotizacion?CotizacionId=${cotizacionId}`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response.status === 204 ? null : response.json();
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };

let createGetEstadoCalificacion =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (solicitudId) => {
    let request = buildRequestWithAuth("GET");

    return fetch(
      `${environment.originacionUrl}/solicitudes/${solicitudId}/calificacion/estado`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        let resultError = parseError(error, "createGetEstadoCalificacion");
        console.error(resultError);
        throw resultError;
      });
  };

let createGetOfertas =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (solicitudId) => {
    let request = buildRequestWithAuth("GET");

    return (
      fetch(
        `${environment.originacionUrl}/solicitudes/${solicitudId}/ofertas?AceptacionAutomatica=false`,
        request
      )
        //return fetch(`${environment.originacionUrl}/solicitudes/201918/ofertas`, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          let resultError = parseError(error, "createGetOfertas");
          console.error(resultError);
          throw resultError;
        })
    );
  };

let createPostSolicitudSeleccionOferta =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (data) => {
    let request = buildRequestWithAuth("POST", data);

    return fetch(
      `${environment.originacionUrl}/solicitudes/${data.Id}/ofertaaceptada`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let resultError = parseError(
          error,
          "createPostSolicitudSeleccionOferta"
        );
        console.error(resultError);
        throw resultError;
      });
  };

let createPostSolicitudCerrarSinOferta =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (data) => {
    let request = buildRequestWithAuth("POST", data);

    return fetch(
      `${environment.originacionUrl}/solicitudes/${data.Id}/cargadasinoferta`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let resultError = parseError(
          error,
          "createPostSolicitudCerrarSinOferta"
        );
        console.error(resultError);
        throw resultError;
      });
  };

let createGetSolicitudes =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (skip = 0, take = 10, filterText, soloPendientes) => {
    let request = buildRequestWithAuth("GET");

    return fetch(
      `${environment.originacionUrl}/solicitudes?skip=${skip}&take=${take}&Search=${filterText}&SoloConTareasPendientes=${soloPendientes}&format=json`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        let resultError = parseError(error, "createGetSolicitudes");
        console.error(resultError);
        throw resultError;
      });
  };

let createGetSolicitudById =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (solicitudId) => {
    let request = buildRequestWithAuth("GET");

    return (
      fetch(`${environment.originacionUrl}/solicitudes/${solicitudId}`, request)
        //return fetch(`${environment.originacionUrl}/solicitudes/201932`, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          let resultError = parseError(error, "createGetSolicitudById");
          console.error(resultError);
          throw resultError;
        })
    );
  };

let createPostAgregarConyuge =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (data) => {
    let request = buildRequestWithAuth("POST", data);
    return fetch(
      `${environment.originacionUrl}/solicitudes/${data.Id}/agregarConyuge`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let resultError = parseError(error, "createPostAgregarConyuge");
        console.error(resultError);
        throw resultError;
      });
  };

let createPostAgregarConyugeCodeudor =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (data) => {
    let request = buildRequestWithAuth("POST", data);
    return fetch(
      `${environment.originacionUrl}/solicitudes/${data.Id}/agregarConyugeCodeudor`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let resultError = parseError(error, "createPostAgregarConyugeCodeudor");
        console.error(resultError);
        throw resultError;
      });
  };

let createPostAgregarCodeudor =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (data) => {
    let request = buildRequestWithAuth("POST", data);
    return fetch(
      `${environment.originacionUrl}/solicitudes/${data.Id}/agregarCodeudor`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let resultError = parseError(error, "createPostAgregarCodeudor");
        console.error(resultError);
        throw resultError;
      });
  };

let createGetPlazosPrestamo =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (solicitudId) => {
    let request = buildRequestWithAuth("GET");
    return fetch(
      `${environment.originacionUrl}/solicitudes/${solicitudId}/plazosPrestamo`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        let resultError = parseError(error, "createGetPlazosPrestamo");
        console.error(resultError);
        throw resultError;
      });
  };

let createGetEstadoSolicitud =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (solicitudId) => {
    let request = buildRequestWithAuth("GET");
    return fetch(
      `${environment.originacionUrl}/solicitudes/${solicitudId}/estado`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        let resultError = parseError(error, "createGetEstadoSolicitud");
        console.error(resultError);
        throw resultError;
      });
  };

let createPostSolicitarConfeccionPrenda =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (data) => {
    let request = buildRequestWithAuth("POST", data);

    return fetch(
      `${environment.originacionUrl}/solicitudes/${data.solicitudId}/estados/confeccionprendasolicitada`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let resultError = parseError(
          error,
          "createPostSolicitarConfeccionPrenda"
        );
        console.error(resultError);
        throw resultError;
      });
  };

export {
  createPostSolicitudNueva,
  createPostContinuarSolicitud,
  createPostSolicitudIngresos,
  createPostSolicitudDomicilioTitular,
  createGetConfiguracionContactos,
  createPostSolicitudAuto,
  createPostSolicitudPreferenciaVehiculo,
  createPostSolicitudImporte,
  createPostSolicitudSeguroCotizacion,
  createGetSolicitudSegurosCotizaciones,
  createGetEstadoCalificacion,
  createGetOfertas,
  createPostSolicitudSeleccionOferta,
  createPostSolicitudCerrarSinOferta,
  createGetSolicitudes,
  createGetSolicitudById,
  createGetSolicitudSegurosInicio,
  createGetSolicitudSegurosResult,
  createPostAgregarConyuge,
  createPostAgregarConyugeCodeudor,
  createPostAgregarCodeudor,
  createGetPlazosPrestamo,
  createGetEstadoSolicitud,
  createPostSolicitarConfeccionPrenda,
};
