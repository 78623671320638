import {
  VEHICULOS_MODELOS_INITED,
  VEHICULOS_MODELOS_COMPLETED,
  VEHICULOS_MODELOS_FAILED
} from "./actions";

const initialState = {
  isLoading: true,
  modelos: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case VEHICULOS_MODELOS_INITED:
      return {
        ...state,
        modelos: [],
        isLoading: true
      };
    case VEHICULOS_MODELOS_FAILED:
      return {
        ...state,
        modelos: [],
        isLoading: false
      };
    case VEHICULOS_MODELOS_COMPLETED:
      return {
        ...state,
        modelos: [...action.payload.modelos],
        isLoading: false
      };
    default:
      return state;
  }
};

export default reducer;
