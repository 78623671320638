const getMGUser = auth0User => {
  if (!auth0User) return null;

  const userInfo = auth0User["http://mg-group.com.ar/userInfo"];

  const isAuthorized =
    userInfo.euroRoles &&
    userInfo.euroPermissions &&
    userInfo.euroRoles.length > 0 &&
    userInfo.euroPermissions.length > 0;

  return {
    sub: auth0User.sub,
    socialNetworkId: auth0User.sub.split("|")[0],
    email: userInfo.email,
    picture: userInfo.picture,
    preferred_username: userInfo.name,
    roles: userInfo.euroRoles,
    perms: userInfo.euroPermissions,
    euroUserId: userInfo.euroUserId,
    isAuthorized: isAuthorized,
    userType: userInfo.userType ? userInfo.userType : "Agenciero",
    validatedIdentity: userInfo.validatedIdentity
  };
};

export default getMGUser;
