import React from "react";
import PropTypes from "prop-types";
import Icon from "@material-ui/core/Icon";
import useTheme from "@material-ui/core/styles/useTheme";
const ResultMessage = 
  ({ 
    backgroundColor, 
    borderColor, 
    style, 
    fontIcon,
    color, 
    title, 
    message, 
    options,
 }) => {
  const theme = useTheme();

  const defaultContainerStyles = {
    marginTop: "5%",
    display: "block",
    boxShadow: "rgb(232, 232, 232) 0px 2px 15px",
    padding: 20,
    lineHeight: 1.8,
    color: theme.palette.custom.textColor,
    margin: "10% auto",
    backgroundColor:
      backgroundColor || theme.palette.custom.white,
    border: "1px solid " + borderColor || theme.palette.custom.grey7
  };

  return (
    <div>
      <div style={{ clear: "both" }} />
      <div
        style={{
          ...defaultContainerStyles,
          ...style
        }}
      >
        <Icon
          className="material-icons"
          style={{
            height: 28,
            verticalAlign: "middle",
            marginRight: 10,
            color: color || theme.palette.custom.textColor
          }}
        >
          {fontIcon || "announcement"}
        </Icon>
        <span
          style={{
            display: "inline-block",
            fontWeight: 700,
            color: color || theme.palette.custom.textColor
          }}
        >
          {title}
        </span>
        <span
          style={{
            display: "block",
            marginTop: 15
          }}
        >
          {message}
        </span>
        {options ? options : ""}
      </div>
    </div>
  );
}

ResultMessage.propTypes = {
  message: PropTypes.any.isRequired,
  title: PropTypes.any.isRequired
};

export default ResultMessage;
