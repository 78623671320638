let createGetContacto =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk }) =>
  (data) => {
    let request = buildRequestWithAuth("GET", data);

    return fetch(`${environment.catalogosUrl}/datoscontacto`, request)
      .then(errorUnlessOk)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };

let createPostContacto =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (solicitudId, data) => {
    let request = buildRequestWithAuth("POST", data);

    return fetch(
      `${environment.originacionUrl}/solicitudes/${solicitudId}/contacto`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let resultError = parseError(error, "createPostContacto");
        console.error(resultError);
        throw resultError;
      });
  };

let createPostContactoConsumo =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (data) => {
    let request = buildRequestWithAuth("POST", data);

    return fetch(
      `${environment.originacionUrl}/consumo/solicitudes/${data.Id}/telefonoContacto`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let resultError = parseError(error, "createPostContactoConsumo");
        console.error(resultError);
        throw resultError;
      });
  };

let createPostEmailConsumo =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (data) => {
    let request = buildRequestWithAuth("POST", data);

    return fetch(
      `${environment.originacionUrl}/consumo/solicitudes/${data.Id}/emailContacto`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let resultError = parseError(error, "createPostEmailConsumo");
        console.error(resultError);
        throw resultError;
      });
  };

export {
  createGetContacto,
  createPostContacto,
  createPostContactoConsumo,
  createPostEmailConsumo,
};
