import { createActions, handleActions } from "redux-actions";
import { tareaEnviadaParaAnalizar } from "../js/actions";
export const {
  submitTareaTelefonoInited,
  submitTareaTelefonoCompleted,
  submitTareaTelefonoFailed
} = createActions(
  "SUBMIT_TAREA_TELEFONO_INITED",
  "SUBMIT_TAREA_TELEFONO_COMPLETED",
  "SUBMIT_TAREA_TELEFONO_FAILED"
);
export const submitTareaTelefono = (
  solicitudId,
  tarea,
  telefonos,
  comentario
) => {
  return async (dispatch, getState, services) => {
    try {
      let tareaUpdated = JSON.parse(JSON.stringify(tarea));
      let dataObj = "";
      let data = tareaUpdated.data;
      if (telefonos.length > 0) {
        if (tareaUpdated.data) {
          dataObj = JSON.parse(tareaUpdated.data);
          let newDataObj = { Telefonos: dataObj.Telefonos.concat(telefonos) };
          data = JSON.stringify(newDataObj);
        } else {
          data = JSON.stringify({ Telefonos: telefonos });
        }
      }

      const request = {
        Comentario: comentario,
        Telefonos: telefonos,
        data: data,
        solicitudId: solicitudId,
        sequence: tarea.sequence
      };

      tareaUpdated.data = data;
      //Actualizo el contenido de la tarea
      if (comentario) {
        tareaUpdated.log.push({ comentario: comentario, usrExterno: true });
      }
      tareaUpdated.estado = "Para_Analizar";

      dispatch(submitTareaTelefonoInited());
      await services.api.postTareaTelefono(solicitudId, request);

      dispatch(tareaEnviadaParaAnalizar(tareaUpdated));
      dispatch(submitTareaTelefonoCompleted());
    } catch (error) {
      console.error(error);
      dispatch(submitTareaTelefonoFailed(error));
    }
  };
};

const TareaTelefonoReducer = handleActions(
  {
    [submitTareaTelefonoInited]: state => {
      return { ...state, submitIsLoading: true };
    },
    [submitTareaTelefonoCompleted]: state => {
      return { ...state, submitIsLoading: false };
    },
    [submitTareaTelefonoFailed]: state => {
      return { ...state, submitIsLoading: false };
    }
  },
  {
    submitIsLoading: false
  }
);
export default TareaTelefonoReducer;
