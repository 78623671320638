import React, { useState } from "react";
import { FiltroEsatadoContext } from "./filtroEstado.context";
import FiltroCreditosEstado, {
  creditosFilters,
} from "components/bussines/creditos/listadoSolicitudes/FiltroCreditosEstado";

export const FiltroEstadoProvider = ({ children }) => {
  const [filtroEstado, setFiltroEstado] = useState(null);
  return (
    <FiltroEsatadoContext.Provider value={{ filtroEstado, setFiltroEstado }}>
      {children}
    </FiltroEsatadoContext.Provider>
  );
};
