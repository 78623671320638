function xmlHttpInvoke(url, accessToken) {
  return new Promise(function(resolve, reject) {
    let xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", "Bearer " + accessToken);
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(JSON.parse(xhr.response));
      } else {
        reject({
          status: xhr.status,
          statusText: xhr.statusText,
        });
      }
    };
    xhr.onerror = (e) => {
      reject(e);
    };
    xhr.send();
  });
}

let createGetAceptacion = ({
  environment,
  localRepository,
}) => async (IdApp, user) => {
  // let request = buildRequestWithAuth("GET");

  const {
    app,
    version,
    idUsuario,
  } = localRepository.terminosYCondiciones.get();
  const { terminosYCondiciones } = environment;
  const { appId: appIdFromEnv, version: versionFromEnv } = terminosYCondiciones;

  let mostrarTerminos = !(
    app === appIdFromEnv &&
    idUsuario === user.euroUserId &&
    version === versionFromEnv
  );

  if (mostrarTerminos) {
    const accessToken = localRepository.accessToken.get();
    const url = `${environment.authUrl}/controldeusuarios/terminos/aceptacion/${IdApp}`;

    return await xmlHttpInvoke(url, accessToken);
  } else {
    return {
      app: appIdFromEnv,
      version: versionFromEnv,
      fechaDesde: "",
      fechaFirma: "",
    };
  }
};

let createPostAceptarTerminos = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
  parseError,
}) => (data) => {
  let request = buildRequestWithAuth("POST", data);

  return fetch(
    `${environment.authUrl}/controldeusuarios/terminos/aceptacion`,
    request
  )
    .then(errorUnlessOk)
    .catch((error) => {
      let resultError = parseError(error, "createPostAceptarTerminos");
      console.error(resultError);
      throw resultError;
    });
};

export { createGetAceptacion, createPostAceptarTerminos };
