import { createActions, handleActions } from "redux-actions";
import objectMap from "libs/objectMap";
export const {
  fetchSolicitudInited,
  fetchSolicitudFailed,
  fetchSolicitudCompleted,

  fetchTareasInited,
  fetchTareasCompleted,
  fetchTareasFailed,
  archivoDownloaded,
  archivoTareasUploadCompleted,
  archivoTareasRemoved,
  submitTareaFileInited,
  submitTareaFileCompleted,
  submitTareaFileFailed,
  tareaEnviadaParaAnalizar,
} = createActions(
  "FETCH_SOLICITUD_INITED",
  "FETCH_SOLICITUD_FAILED",
  "FETCH_SOLICITUD_COMPLETED",

  "FETCH_TAREAS_INITED",
  "FETCH_TAREAS_COMPLETED",
  "FETCH_TAREAS_FAILED",

  "ARCHIVO_DOWNLOADED",
  "ARCHIVO_TAREAS_UPLOAD_COMPLETED",
  "ARCHIVO_TAREAS_REMOVED",
  "SUBMIT_TAREA_FILE_INITED",
  "SUBMIT_TAREA_FILE_COMPLETED",
  "SUBMIT_TAREA_FILE_FAILED",
  "TAREA_ENVIADA_PARA_ANALIZAR"
);

export function fetchTareas(solicitudId) {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchTareasInited());
      const response = await services.api.getTareasSolicitud(solicitudId);

      const tareas = response
        .filter((tarea) => tarea.estado !== "Excepcionada" && !tarea.interna)
        .map((tarea) => {
          var archivos = tarea.archivos.map((a) => {
            let extraFields = undefined;
            if (a.url) {
              let fileName = a.url.split("/").pop().split(".")[0];
              extraFields = {
                fileName,
                id: a.id ? a.id : fileName.toLowerCase(),
                name: fileName,
                loaded: true,
              };
            }

            return {
              ...a,
              tareaId: tarea.tareaId,
              ...extraFields,
            };
          });

          return { ...tarea, archivos };
        });

      const tareasOk = tareas.filter((tarea) => tarea.estado === "OK");
      const tareasEnAnalisis = tareas.filter(
        (tarea) => tarea.estado === "Para_Analizar"
      );
      const tareasPendientes = tareas.filter(
        (t) => t.estado !== "OK" && t.estado !== "Para_Analizar"
      );
      const finalTareas = tareasPendientes
        .concat(tareasEnAnalisis)
        .concat(tareasOk);

      dispatch(fetchTareasCompleted(finalTareas));
    } catch (error) {
      dispatch(services.errorHandler.checkErrorAction(error));
      dispatch(fetchTareasFailed(error));
    }
  };
}

export const fetchSolicitud = (solicitudId) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchSolicitudInited());
      const response = await services.api.getSolicitudById(solicitudId);
      dispatch(fetchSolicitudCompleted(response));
    } catch (error) {
      dispatch(fetchSolicitudFailed(error));
    }
  };
};

export const uploadArchivoInited = ({
  fieldName,
  file,
  metadata,
  load,
  error,
  progress,
  abort,
}) => {
  return async (dispatch, getState, services) => {
    const directory = "temp";
    const fileService = services.fileService(directory);
    try {
      await fileService.upload(file, load, progress);
    } catch (e) {
      console.error(e);
      error(e.message);
      services.errorHandler.logRemoteError(
        e,
        uploadArchivoInited,
        [fieldName, file, metadata, load, error, progress, abort],
        getState()
      );
      dispatch(services.errorHandler.checkErrorAction(e));
    }
  };
};

export const removeArchivoInited = ({ serverId, load, error }) => {
  return async (dispatch, getState, services) => {
    const directory = "temp";
    const fileService = services.fileService(directory);
    try {
      load();
      await fileService.delete(serverId);
    } catch (e) {
      console.error(e);
      error(e);
      services.errorHandler.logRemoteError(
        e,
        removeArchivoInited,
        [serverId, load, error],
        getState()
      );
      dispatch(services.errorHandler.checkErrorAction(e));
    }
  };
};

export const downloadArchivoInited = (solicitudId, tarea, fileName, fileId) => {
  return async (dispatch, getState, services) => {
    const directory = `solicitudes/${solicitudId}/tareas/${tarea.sequence}/archivos`;
    const fileService = services.fileService(directory);
    try {
      const response = await fileService.download(
        encodeURIComponent(fileName.toLowerCase()).replace(/%2520/g, "%20")
      );

      dispatch(
        archivoDownloaded({
          tareaId: tarea.tareaId,
          id: fileId ? fileId : fileName.toLowerCase(),
          url: response.url,
          name: fileName,
          extension: fileName.split(".").pop(),
        })
      );
    } catch (e) {
      console.error(e);
      dispatch(services.errorHandler.checkErrorAction(e));
    }
  };
};

export const submitTareaFile = (solicitudId, tarea, comentario) => {
  return async (dispatch, getState, services) => {
    try {
      let tareaUpdated = JSON.parse(JSON.stringify(tarea));
      if (comentario) {
        tareaUpdated.log.push({ comentario: comentario, usrExterno: true });
      }
      tareaUpdated.estado = "Para_Analizar";

      const form = {
        Comentario: comentario,
        data: "",
        solicitudId: solicitudId,
        tareaId: tarea.tareaId,
        sequence: tarea.sequence,
        archivos: tarea.tempFiles,
      };

      dispatch(submitTareaFileInited());
      const response = await services.api.postTarea(
        solicitudId,
        tarea.sequence,
        form
      );
      response.urls.map((url) => {
        const fileName = decodeURI(url.split("/").pop());
        dispatch(downloadArchivoInited(solicitudId, tarea, fileName));
      });

      dispatch(tareaEnviadaParaAnalizar(tareaUpdated));
      dispatch(submitTareaFileCompleted());
    } catch (error) {
      console.error(error);
      dispatch(submitTareaFileFailed(error));
    }
  };
};

const tareasSolicitudReducer = handleActions(
  {
    [submitTareaFileInited]: (state) => {
      return { ...state, submitIsLoading: true };
    },
    [submitTareaFileCompleted]: (state) => {
      return { ...state, submitIsLoading: false };
    },
    [submitTareaFileFailed]: (state) => {
      return { ...state, submitIsLoading: false };
    },
    [fetchSolicitudInited]: (state) => {
      return { ...state, isLoading: true };
    },
    [fetchSolicitudCompleted]: (state, action) => {
      const titular = action.payload.personas.find(
        (persona) => persona.tipoRelacion === "Titular"
      );
      const conyuge = action.payload.personas.find(
        (persona) => persona.tipoRelacion === "Conyuge"
      );
      const codeudor = action.payload.personas.find(
        (persona) => persona.tipoRelacion === "Codeudor"
      );
      const conyugeCodeudor = action.payload.personas.find(
        (persona) => persona.tipoRelacion === "Conyuge_del_Codeudor"
      );

      return {
        ...state,
        solicitud: {
          nombresTitular: titular.nombre,
          apellidosTitular: titular.apellido,
          importeSolicitado: action.payload.importeNetoAPercibir,
          situacionOp: action.payload.situacion,
          conyuge: conyuge,
          codeudor: codeudor,
          conyugeCodeudor: conyugeCodeudor,
        },
        isLoading: false,
        error: false,
      };
    },
    [fetchSolicitudFailed]: (state) => {
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    },
    [fetchTareasInited]: (state) => {
      return {
        ...state,
        tareasIds: {},
        tareasById: {},
        isLoading: true,
      };
    },
    [fetchTareasCompleted]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        tareasIds: action.payload.map((tarea) => tarea.tareaId),
        tareasById: action.payload.reduce(function (map, tarea) {
          tarea.tempFiles = [];
          map[tarea.tareaId] = tarea;
          return map;
        }, {}),
        error: false,
      };
    },
    [fetchTareasFailed]: (state) => {
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    },
    [archivoDownloaded]: (state, action) => {
      let withArchivosUpdated = objectMap(state.tareasById, function (tarea) {
        const newArchivo = tarea.archivos.find(
          (archivo) => archivo.id === action.payload.id
        );

        if (tarea.tareaId === action.payload.tareaId) {
          if (newArchivo) {
            return {
              ...tarea,
              archivos: tarea.archivos.map((archivo) =>
                archivo.id === action.payload.id
                  ? {
                      ...action.payload,
                      loaded: true,
                    }
                  : archivo
              ),
            };
          } else {
            const archivosTotal = [
              ...tarea.archivos,
              {
                ...action.payload,
                loaded: true,
              },
            ];
            return {
              ...tarea,
              archivos: archivosTotal,
            };
          }
        } else {
          return tarea;
        }
      });
      return {
        ...state,
        tareasById: withArchivosUpdated,
      };
    },
    [archivoTareasUploadCompleted]: (state, action) => {
      let withTempFilesAdded = objectMap(state.tareasById, function (tarea) {
        return tarea.tareaId === action.payload.tareaId
          ? {
              ...tarea,
              tempFiles: tarea.tempFiles.concat([
                {
                  fileName: action.payload.filename,
                  serverId: action.payload.serverId,
                },
              ]),
            }
          : tarea;
      });
      return {
        ...state,
        tareasById: withTempFilesAdded,
      };
    },
    [archivoTareasRemoved]: (state, action) => {
      let withTempFilesRemoved = objectMap(state.tareasById, function (tarea) {
        return tarea.tareaId === action.payload.tareaId
          ? {
              ...tarea,
              tempFiles: tarea.tempFiles.filter(
                (t) => t.fileName !== action.payload.filename
              ),
            }
          : tarea;
      });

      return {
        ...state,
        tareasById: withTempFilesRemoved,
      };
    },
    [tareaEnviadaParaAnalizar]: (state, action) => {
      let tareas = state.tareasById;
      tareas[action.payload.tareaId] = action.payload;
      return {
        ...state,
        tareasById: tareas,
      };
    },
  },
  {
    isLoading: false,
    submitIsLoading: false,
    error: false,
    tareasIds: [],
    tareasById: {},
    solicitud: {},
  }
);

export default tareasSolicitudReducer;
