import uuidv4 from "uuid/v4";

let createGetTareasSolicitud = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
  parseError
}) => solicitudId => {
  let request = buildRequestWithAuth("GET");

  return fetch(
    `${environment.originacionUrl}/solicitudes/${solicitudId}/tareas/solicitadas`,
    request
  )
    .then(errorUnlessOk)
    .then(response => {
      return response.json();
    })
    .catch(error => {
      let resultError = parseError(error, "createGetTareasSolicitud");
      console.error(resultError);
      throw resultError;
    });
};

let createGetTareaSolicitud = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
  parseError
}) => (solicitudId, sequence) => {
  let request = buildRequestWithAuth("GET");

  return fetch(
    `${environment.originacionUrl}/solicitudes/${solicitudId}/tareas/${sequence}`,
    request
  )
    .then(errorUnlessOk)
    .then(response => {
      return response.json();
    })
    .catch(error => {
      let resultError = parseError(error, "createGetTareaSolicitud");
      console.error(resultError);
      throw resultError;
    });
};

let createPostTarea = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
  parseError
}) => (solicitudId, sequence, body) => {
  let request = buildRequestWithAuth("POST", body);

  return fetch(
    `${environment.originacionUrl}/solicitudes/${solicitudId}/tareas/${sequence}/estado/para_analizar`,
    request
  )
    .then(errorUnlessOk)
    .then(response => {
      return response.json();
    })
    .catch(error => {
      let resultError = parseError(error, "createPostTarea");
      console.error(resultError);
      throw resultError;
    });
};

let createGetFetchArchivoTarea = ({
  fetch,
  buildRequestWithAuth,
  errorUnlessOk
}) => imageUrl => {
  let request = buildRequestWithAuth("GET");
  return fetch(`${imageUrl}`, request)
    .then(errorUnlessOk)
    .then(response => response.blob())
    .then(images => {
      const outside = URL.createObjectURL(images);
      return outside;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

let createGetFetchArchivoTareaThumbnail = ({
  fetch,
  buildRequestWithAuth,
  errorUnlessOk
}) => imageUrl => {
  let request = buildRequestWithAuth("GET", {
    Accept: "application/json",
    "Content-Type": "application/thumbnail"
  });
  return fetch(`${imageUrl}`, request)
    .then(errorUnlessOk)
    .then(response => response.blob())
    .then(images => {
      const outside = URL.createObjectURL(images);
      return outside;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

let createPostArchivoTarea = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk
}) => (fieldName, file, load, error, progress) => {
  let request = buildRequestWithAuth("GET");
  let key = uuidv4();

  fetch(`${environment.presignerUrl}/sign?key=temp/${key}.jpg`, request)
    .then(errorUnlessOk)
    .then(res => res.json())
    .then(json => {
      var xhr = new XMLHttpRequest();
      xhr.upload.onprogress = e => {
        progress(e.lengthComputable, e.loaded, e.total);
      };
      xhr.open("PUT", json.url);
      xhr.setRequestHeader("Content-Type", "application/octet-stream");
      xhr.onload = () => {
        if (xhr.status === 200) {
          load(xhr.responseText);
          //resolve(JSON.parse(xhr.response));
          console.log("Tarea's file uploaded!"); // TODO: no habria que mostrar feedback al usuario??
        } else {
          error("error");
        }
      };
      xhr.onerror = e => {
        console.log(e); // TODO: no habria que mostrar feedback al usuario??
      };
      xhr.send(file);
    })
    .catch(err => {
      console.log(err); // TODO: no habria que mostrar feedback al usuario??
    });
  /*return {
    abort: () => {
      request.abort();
      abort();
    }
  };*/
};

let createDeleteArchivoTarea = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk
}) => (uniqueFileId, load, error) => {
  let request = buildRequestWithAuth("DELETE", {
    "Content-Type": "text-plain"
  });
  return (
    fetch(
      `${environment.presignerUrl}/sign?key=temp/${uniqueFileId}.jpg`,
      request
    )
      //fetch(`${environment.originacionUrl}/files/temp/${uniqueFileId}`, request)
      //fetch(`http://localhost:5000/files/temp/${uniqueFileId}`, request)
      .then(errorUnlessOk)
      .then(() => {
        load();
      })
      .catch(e => {
        error(e);
      })
  );
};

let createPostTareaConyuge = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
  parseError
}) => (solicitudId, data) => {
  let request = buildRequestWithAuth("POST", data);

  return fetch(
    `${environment.originacionUrl}/solicitudes/${solicitudId}/tareas/conyuge/para_analizar`,
    request
  )
    .then(errorUnlessOk)
    .then(response => {
      return response.json();
    })
    .catch(error => {
      let resultError = parseError(error, "createPostTareaConyuge");
      console.error(resultError);
      throw resultError;
    });
};

let createPostTareaTelefono = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
  parseError
}) => (solicitudId, data) => {
  let request = buildRequestWithAuth("POST", data);

  return fetch(
    `${environment.originacionUrl}/solicitudes/${solicitudId}/tareas/telefono/para_analizar`,
    request
  )
    .then(errorUnlessOk)
    .then(response => {
      return response.json();
    })
    .catch(error => {
      let resultError = parseError(error, "createPostTareaTelefono");
      console.error(resultError);
      throw resultError;
    });
};

let createPostTareaCodeudor = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
  parseError
}) => (solicitudId, data) => {
  let request = buildRequestWithAuth("POST", data);

  return fetch(
    `${environment.originacionUrl}/solicitudes/${solicitudId}/tareas/codeudor/para_analizar`,
    request
  )
    .then(errorUnlessOk)
    .then(response => {
      return response.json();
    })
    .catch(error => {
      let resultError = parseError(error, "createPostTareaCodeudor");
      console.error(resultError);
      throw resultError;
    });
};

export {
  createGetTareasSolicitud,
  createGetTareaSolicitud,
  createPostTarea,
  createGetFetchArchivoTarea,
  createGetFetchArchivoTareaThumbnail,
  createPostArchivoTarea,
  createDeleteArchivoTarea,
  createPostTareaConyuge,
  createPostTareaCodeudor,
  createPostTareaTelefono
};
