import { createActions, handleActions } from "redux-actions";

const {
  business: {
    marcaVehiculo: {
      fetchMarcasVehiculosInited,
      fetchMarcasVehiculosCompleted,
      fetchMarcasVehiculosFailed,
    },
  },
} = createActions({
  business: {
    marcaVehiculo: {
      fetchMarcasVehiculosInited: () => ({}),
      fetchMarcasVehiculosCompleted: (response) => ({ response }),
      fetchMarcasVehiculosFailed: (error) => ({ error }),
    },
  },
});

const fetchMarcasVehiculos = () => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchMarcasVehiculosInited());
      const response = await services.api.getVehiclesMarcas();
      dispatch(fetchMarcasVehiculosCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(fetchMarcasVehiculosFailed(error));
    }
  };
};

const initialState = {
  marcas: [],
  isLoading: false,
};

const marcaVehiculoSelectorReducer = handleActions(
  {
    [fetchMarcasVehiculosInited]: () => {
      return {
        ...initialState,
        isLoading: true,
      };
    },
    [fetchMarcasVehiculosCompleted]: (state, action) => {
      const marcas = action.payload.response.map((item) => ({
        value: item,
        label: item,
      }));
      return {
        ...initialState,
        marcas: marcas,
        isLoading: false,
      };
    },
    [fetchMarcasVehiculosFailed]: () => {
      return {
        ...initialState,
        isLoading: false,
      };
    },
  },
  initialState
);

export default marcaVehiculoSelectorReducer;
export {
  fetchMarcasVehiculos,
  fetchMarcasVehiculosInited,
  fetchMarcasVehiculosCompleted,
  fetchMarcasVehiculosFailed,
};
