import {
  HIDE_TOPBAR,
  SHOW_TOPBAR,
  TOGGLE_OPEN_MAINMENU,
  TOGGLE_OPEN_NOTIFICATIONBAR,
  OPEN_PROFILEMENU,
  OPEN_MAINMENU,
  MAINMENU_SET
} from "./actions";

const initialState = {
  hide: false,
  openMainMenu: false,
  openNotificationBar: false,
  openProfileMenu: false,
  archorELProfileMenu: null,
  currentTitle: "",
  titleMenuItems: [],
  backUrl: "/",
  menuItem: "Inicio"
};

const topBar = (state = initialState, action) => {
  switch (action.type) {
    case HIDE_TOPBAR:
      return { ...state, hide: true, backUrl: action.payload.backUrl };
    case SHOW_TOPBAR:
      return { ...state, hide: false };
    case TOGGLE_OPEN_MAINMENU:
      return { ...state, openMainMenu: !state.openMainMenu };
    case TOGGLE_OPEN_NOTIFICATIONBAR:
      return { ...state, openNotificationBar: action.payload.open };
    case OPEN_PROFILEMENU:
      return {
        ...state,
        openProfileMenu: action.payload.open,
        archorELProfileMenu: action.payload.anchorEl
      };
    case OPEN_MAINMENU:
      return { ...state, openMainMenu: action.payload.open };
    case MAINMENU_SET:
      return {
        ...state,
        currentTitle: action.payload.title,
        backUrl: action.payload.backUrl,
        menuItem: action.payload.menuItem
      };
    default:
      return state;
  }
};

export default topBar;
