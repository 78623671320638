import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import useAsyncReducer from "components/app/useAsyncReducer";
import LoadingView from "components/styled/loadingView/LoadingView";
import authenticationReducers from "./Reducers";
import { AsyncRecoverPassword } from "componentes/app/LazyComponents";
import loadable from "@loadable/component";

const Validating = loadable(() => import("componentes/auth/Validating"), {
  resolveComponent: (comps) => comps.Validating,
});
const LoginFormContainer = loadable(() =>
  import("componentes/user/LoginContainer")
);
const IngresarAppInfo = loadable(() =>
  import("componentes/auth/IngresarAppInfo")
);
const IngresarAppSuccess = loadable(() =>
  import("componentes/auth/IngresarAppSuccess")
);
const SignupAgencia = loadable(() =>
  import("./signup/agenciero/SignupAgencia")
);
const SignupClienteFinal = loadable(() =>
  import("./signup/clienteFinal/SignupClienteFinal")
);

const SignupProductor = loadable(() =>
  import("./signup/productor/SignupProductor")
);
const StepperProductor = loadable(() =>
  import("./signup/productor/StepperProductor")
);
const SelectorTipoProductor = loadable(() =>
  import("./signup/productor/SelectorTipoProductor")
);
const SignupProductorSancor = loadable(() =>
  import("./signup/productor/SingupProductorSancor")
);

const AuthenticationRoutes = () => {
  const loading = useAsyncReducer([authenticationReducers]);
  return (
    <>
      {loading && <LoadingView />}
      {!loading && (
        <Switch>
          <Route exact path="/validating" component={Validating} />
          <Route path="/agencias/signup/:position?" component={SignupAgencia} />
          <Route
            path="/clientes/signup/:position?"
            component={SignupClienteFinal}
          />
          <Route path="/productores/tipo" component={SelectorTipoProductor} />
          <Route
            exact
            path="/productores/sancor"
            component={SignupProductorSancor}
          />
          <Route
            exact
            path="/productores/sancor/signup"
            component={StepperProductor}
          />
          <Route
            exact
            path="/productores/otros/signup"
            component={SignupProductor}
          />
          <Route exact path="/login/old" component={LoginFormContainer} />
          <Route
            exact
            path="/login/password/:token"
            component={AsyncRecoverPassword}
          />
          <Route
            exact
            path="/actualizando/inicio"
            component={IngresarAppInfo}
          />
          <Route
            exact
            path="/actualizando/success"
            component={IngresarAppSuccess}
          />
          <Redirect to="/validating" /> {/*fallback to login*/}
        </Switch>
      )}
    </>
  );
};

export default AuthenticationRoutes;
