import localRepository from "../../../../localRepository";

export const FETCHLOCALIDADES_INITED = "FETCHLOCALIDADES_INITED";
export const FETCHLOCALIDADES_COMPLETED = "FETCHLOCALIDADES_COMPLETED";
export const FETCHLOCALIDADES_FAILED = "FETCHLOCALIDADES_FAILED";

export const fetchLocalidades = provinciaId => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchLocalidadesInited());
      let response = await services.api.getProvinciasLocalidades(provinciaId);
      dispatch(fetchLocalidadesCompleted(response, provinciaId));
    } catch (error) {
      console.error(error);
      dispatch(fetchLocalidadesFailed(error));
    }
  };
};

const fetchLocalidadesInited = () => {
  return {
    type: FETCHLOCALIDADES_INITED
  };
};

const fetchLocalidadesCompleted = (response, provinciaId) => {
  let localidades = [];

  if (
    typeof response !== "undefined" &&
    response !== null &&
    response.length !== 0 &&
    response.localidades &&
    response.localidades.length > 0
  ) {
    localidades = response.localidades
      .filter((v, i) => response.localidades.indexOf(v) === i)
      .map(x => ({
        value: x,
        label: x
      }));
  }
  const locMostUsed = localRepository.mostUsedLoc
    .get()
    .filter(loc => loc.provId === provinciaId)
    .sort((x, y) => y.count - x.count)
    .slice(0, 3);
  if (locMostUsed.length > 0) {
    const frecuentes = [
      {
        label: "Frecuentes",
        options: locMostUsed
      },
      { label: "Todos", options: localidades }
    ];
    localidades = frecuentes;
  }
  return {
    type: FETCHLOCALIDADES_COMPLETED,
    payload: {
      localidades
    }
  };
};

const fetchLocalidadesFailed = error => {
  return {
    type: FETCHLOCALIDADES_FAILED,
    payload: {
      error
    }
  };
};
