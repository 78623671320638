import { createActions, handleActions } from "redux-actions";

const {
  authentication: {
    signupProductor: {
      submitSignupProductorInited,
      submitSignupProductorCompleted,
      submitSignupProductorFailed,
    },
  },
} = createActions({
  authentication: {
    signupProductor: {
      submitSignupProductorInited: (props) => ({ props }),
      submitSignupProductorCompleted: () => ({}),
      submitSignupProductorFailed: (error) => ({ error }),
    },
  },
});

const initialState = {
  loading: false,
  finished: false,
  error: null,
};

const submitSignupProductor = (data) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(submitSignupProductorInited({ data }));
      await services.api.postSignupProductor(data);
      dispatch(submitSignupProductorCompleted());
    } catch (error) {
      if (error.responseStatus.message)
        dispatch(submitSignupProductorFailed(error.responseStatus.message));
      else dispatch(submitSignupProductorFailed());
    }
  };
};

const signupProductorReducer = handleActions(
  {
    [submitSignupProductorInited]: (state) => {
      return {
        ...state,
        error: null,
        finished: false,
        loading: true,
      };
    },
    [submitSignupProductorCompleted]: (state) => {
      return {
        ...state,
        loading: false,
        finished: true,
      };
    },
    [submitSignupProductorFailed]: (state, action) => {
      return {
        ...state,
        loading: false,
        finished: true,
        error: {
          message: action.payload.error,
        },
      };
    },
  },
  initialState
);

export default signupProductorReducer;
export { submitSignupProductor };
