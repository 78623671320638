import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

class MessageViewer extends React.Component {
  render() {
    return (
      <span>
        <ToastContainer
          position="top-right"
          type="error"
          autoClose={5000}
          closeButton={false}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnHover
        />
      </span>
    );
  }
}

export default MessageViewer;
