let createGetCalculadorResult =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk }) =>
  (data) => {
    let request = buildRequestWithAuth("GET", data);
    return fetch(
      `${environment.originacionUrl}/calculador?AnioAuto=${
        data.AnioAuto
      }&tipoCredito=${data.TipoCredito}&tipoFinanciacion=${
        data.TipoFinanciacion
      }&Capital=${data.Capital}&SucursalId=${
        data.SucursalId ? data.SucursalId : ""
      }`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };

export { createGetCalculadorResult };
