const TODAS_COBERTURAS = "TodasCoberturas";
const RC = "RC";
const TC = "TCBase";
const TP = "TCPremium";
const TR = "TR";

const tipoPoliza = {
  //[TC]: "TERCEROS COMPLETO",
  [TC]: "RESPONSIBILIDAD CIVIL",
  [TC]: "INCENDIO TOTAL Y ROBO",
  [TP]: "TERCEROS PREMIUM",
  [TR]: "TODO RIESGO",
};

const tipoPolizaFiltros = {
  [TODAS_COBERTURAS]: "Todas las coberturas",
  [TC]: "Terceros completo",
  [TP]: "Terceros premium",
  [TR]: "Todo riesgo",
  [RC]: "Responsabilidad civil",
};

const coberturasOrder = {
  [TC]: 1,
  [TP]: 2,
  [TR]: 3,
  [RC]: 4,
};

export {
  TC,
  TP,
  TR,
  coberturasOrder,
  TODAS_COBERTURAS,
  tipoPoliza,
  tipoPolizaFiltros,
};
