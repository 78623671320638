import environment from "environment";
import {
  CloudWatchLogsClient,
  CreateLogStreamCommand,
  DescribeLogStreamsCommand,
  PutLogEventsCommand,
} from "@aws-sdk/client-cloudwatch-logs";
import localRepository from "localRepository";
import uuidv4 from "uuid/v4";
import { format } from "date-fns";

interface ILogParams {
  logEvents: any[];
  logGroupName: string;
  logStreamName: string;
}

const cloudwatchClient = new CloudWatchLogsClient({
  region: "us-east-1",
  credentials: {
    accessKeyId: environment.awsLogs.apiKey!,
    secretAccessKey: environment.awsLogs.apiSecret!,
  },
});

export const createLogRemoteError = () => {
  if (environment.name === "localhost") {
    return (
      error: unknown,
      func: () => unknown,
      params: unknown,
      currentState: any
    ) => {
      const exception = error instanceof Error ? error : JSON.stringify(error);
      console.error("remote error", {
        message: JSON.stringify({
          exception,
          function: func.name,
          params: params,
          state: currentState,
        }),
        timestamp: new Date().getTime(),
      });
    };
  }

  const logRemoteError = async (
    error: unknown,
    func: Function,
    params: unknown,
    currentState: any
  ) => {
    try {
      const exception = error instanceof Error ? error : JSON.stringify(error);
      const logEvent = {
        message: JSON.stringify({
          exception,
          function: func.name,
          params: params,
          state: currentState,
        }),
        timestamp: new Date().getTime(),
      };

      const logGroupName = environment.awsLogs.logGroup!;
      let localLogStreamData = localRepository.logStreamData.get();

      let logStreamName = localLogStreamData.name;
      const user = localRepository.user.get();

      if (!validateLocalLogStreamData(localLogStreamData))
        if (user) {
          logStreamName = await createLogStream(
            logGroupName,
            `${user.email} - ${uuidv4()}`
          );
        } else {
          const LogStream = await checkIfLogStreamExists(
            logGroupName,
            format(new Date(), "yyyy/MM/dd")
          );

          // this logic is necessary because if the user reload the app in the login screen
          // the localRepository is flushed
          if (!LogStream) {
            logStreamName = await createLogStream(
              logGroupName,
              format(new Date(), "yyyy/MM/dd")
            );
          } else if (LogStream.logStreamName) {
            //If AWS doesn't send logStreamName on successfull call
            //we won't log
            setLocalLogStreamData(LogStream.logStreamName);
            logStreamName = LogStream.logStreamName;
          }
        }

      const logParams: ILogParams = {
        logEvents: [logEvent],
        logGroupName,
        logStreamName,
      };

      sendLogToCloudwatch(logParams);
    } catch (error) {
      console.error("remote log not working!!!!!!!!!!!!!!!", error);
    }
  };

  return logRemoteError;
};

const createLogStream = async (logGroupName: string, logStreamName: string) => {
  const createLogStreamCommand = new CreateLogStreamCommand({
    logGroupName,
    logStreamName,
  });

  await cloudwatchClient
    .send(createLogStreamCommand)
    .then(() => {
      setLocalLogStreamData(logStreamName);
    })
    .catch((error) => console.log(error));

  return logStreamName;
};

const sendLogToCloudwatch = (logParams: ILogParams) => {
  const command = new PutLogEventsCommand(logParams);

  cloudwatchClient.send(command).catch((error) => {
    console.log({ error });
  });
};

const validateLocalLogStreamData = (logStreamData: any) => {
  if (!logStreamData) return false;

  const { name, expiresAt } = logStreamData;

  if (!name) return false;
  if (!expiresAt) return false;
  if (expiresAt <= Date.now()) return false;

  return true;
};

const setLocalLogStreamData = (logStreamName: string) => {
  localRepository.logStreamData.set({
    name: logStreamName,
    expiresAt: Date.now() + 4 * 60 * 60 * 1000,
  });
};

const checkIfLogStreamExists = async (
  logGroupName: string,
  logStreamName: string
) => {
  const describeLogStreamCommand = new DescribeLogStreamsCommand({
    logGroupName: logGroupName,
    logStreamNamePrefix: logStreamName,
  });

  const logStreamData = await cloudwatchClient
    .send(describeLogStreamCommand)
    .then((data) => {
      if (data.logStreams && data.logStreams[0]) return data.logStreams[0];
    });

  return logStreamData;
};
