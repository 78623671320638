import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { logEventAnalytics } from "libs/amplitudeHelper";
import { useSelector } from "react-redux";

const useReduxSelector = () =>
  useSelector((state) => ({
    deviceInfo: state.nativeMessagesReducer.deviceInfo,
  }));
const useLocationAnalytics = () => {
  const { deviceInfo } = useReduxSelector();
  let location = useLocation();
  useEffect(() => {
    try {
      logEventAnalytics("urlChanged", {
        location: location.pathname,
        deviceInfo: deviceInfo,
      });
    } catch (err) {
      console.error("Analytics error", err);
    }
  }, [location]);
};

export default useLocationAnalytics;
