const ERROR_CODES = {
  400: "BadRequest",
  401: "Unauthorized",
  403: "Unauthorized", // TODO: feo feo, cambiar
  409: "Conflict",
};

export const errorUnlessOk = (response) => {
  return new Promise((resolve, reject) => {
    if (Object.keys(ERROR_CODES).includes(`${response.status}`)) {
      if (isJsonResponse(response)) {
        response.json().then((json) => {
          if (!json.responseStatus) {
            json = {
              ...json,
              responseStatus: {
                errorCode: ERROR_CODES[response.status],
                message: "",
              },
            };
          }
          reject(json);
        });
      } else
        reject({
          responseStatus: {
            errorCode: ERROR_CODES[response.status],
            message: "",
          },
        });
    } else if (!response.ok) {
      reject({
        responseStatus: {
          errorCode: `GenericError-StatusCode-${response.status}`,
          message: "",
        },
      });
    } else {
      resolve(response);
    }
  });
};

const isJsonResponse = (response) => {
  const contentType = response.headers.get("content-type");
  return contentType && contentType.indexOf("application/json") !== -1;
};

export const convertToQueryString = (params) =>
  Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");

export const buildRequest = (
  verb,
  data,
  headers = { Accept: "application/json", "Content-Type": "application/json" }
) => {
  let request = {
    method: verb,
    retries: 2,
    retryDelay: 30000,
  };
  if (headers !== null) request.headers = headers;
  if (verb === "POST") {
    request.body = JSON.stringify(data);
  } else if (verb === "PUT") {
    request.body = JSON.stringify(data);
  }
  return request;
};

export const createBuildRequestWithAuth = ({ localRepository, headers }) => (
  verb,
  data,
  headersReplace = headers || {
    Accept: "application/json",
    "Content-Type": "application/json",
  }
) => {
  let request = buildRequest(verb, data, headersReplace);

  const accessToken = localRepository.accessToken.get();

  if (accessToken) request.headers["Authorization"] = "Bearer " + accessToken;

  return request;
};

export const isError = (e) => {
  return (
    e &&
    e.stack &&
    e.message &&
    typeof e.stack === "string" &&
    typeof e.message === "string"
  );
};

export const parseError = (error, functionName) => {
  if (isError(error)) {
    return { message: error.message, functionName: functionName };
  } else {
    return error;
  }
};
