const createGetUserExists = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk
}) => (idEuro, email, userAuthId, displayName) => {
  let request = buildRequestWithAuth("GET");
  let query = "";

  if (idEuro)
    query = `?euroUserId=${idEuro}&email=${email}&userAuthId=${userAuthId}&displayName=${displayName}`;
  else
    query = `?email=${email}&userAuthId=${userAuthId}&displayName=${displayName}`;

  return fetch(
    `${environment.authUrl}/controldeusuarios/migracion${query}`,
    request
  )
    .then(errorUnlessOk)
    .then(response => {
      return response.json();
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export { createGetUserExists };
