import React, { lazy, Suspense } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = () => (
  <div style={{ display: "flex", justifyContent: "center", marginTop: 90 }}>
    <CircularProgress  />
  </div>
);

const SuspenseLoading = ({ children }) => <Suspense fallback={<Loading />}>{children}</Suspense>;

const LazyRecoverPassword = lazy(() => import("../user/RecoverPasswordContainer"));
const AsyncRecoverPassword = () => <SuspenseLoading><LazyRecoverPassword /></SuspenseLoading>;

export {
  AsyncRecoverPassword,
};
