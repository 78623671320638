import { useEffect } from "react";
import { withRouter } from "react-router-dom";

const platform = require("platform");
const isiOS = platform.os.family === "iOS";

const NativeAppMessaging = ({ onNativeMessageReceived, history }) => {
  useEffect(() => {
    window.navigateTo = (url) => history.push(url);

    let messageHandler = (event) => {
      let eventDataObj = null;
      try {
        eventDataObj = JSON.parse(event.data);

        if (!eventDataObj.type) return;
        if (
          eventDataObj.type === "RN_NATIVE_CREDENTIALS" &&
          eventDataObj.payload
        )
          localStorage.setItem(
            "nativeCredentials",
            JSON.stringify(eventDataObj.payload.credentials)
          );

        if (eventDataObj.type.startsWith("RN_"))
          onNativeMessageReceived(eventDataObj);
        //eslint-disable-next-line
      } catch (error) {}
    };

    if (isiOS)
      window.addEventListener(
        "message",
        (event) => messageHandler(event),
        false
      );
    else
      document.addEventListener(
        "message",
        (event) => messageHandler(event),
        false
      );
  }, []);

  return null;
};

export default withRouter(NativeAppMessaging);
