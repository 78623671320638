import amplitude from "amplitude-js";
import environment from "../environment";

// docs: https://developers.amplitude.com/

let disableAnalytics = environment.name === "localhost";
//disableAnalytics = false;

export const initAnalytics = (appVersion) => {
  if (disableAnalytics) return; // disable analytics on localhost

  amplitude.getInstance().init(environment.aplitudeAPIkey);
  amplitude.getInstance().setVersionName(appVersion);
};

export const logOutUserAnalytics = () => {
  if (disableAnalytics) return; // disable analytics on localhost

  amplitude.getInstance().setUserId(null);
  //amplitude.getInstance().regenerateDeviceId();
};

export const logInUserAnalytics = (
  email,
  name,
  userId,
  euroUserId,
  appVersion
) => {
  if (disableAnalytics) return; // disable analytics on localhost

  amplitude.getInstance().setUserId(userId);
  amplitude.getInstance().setUserProperties({
    email,
    nombre: name,
    userId,
    euroUserId,
    appVersion,
  });
};

export const logEventAnalytics = (eventName, eventData) => {
  if (disableAnalytics) return; // disable analytics on localhost

  amplitude.getInstance().logEvent(eventName, eventData);
};
