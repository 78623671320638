const createPostReporteConsumo =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk, parseError }) =>
  (data) => {
    const requestBody = {
      FechaDesde: data.FechaDesde,
      FechaHasta: data.FechaHasta,
      Estados: data.Estados
    };

    let request = buildRequestWithAuth("POST", requestBody, {
      "Content-Type": "application/json",
    });

    return fetch(`${environment.originacionUrl}/consumo/reportes`, request)
      .then(errorUnlessOk)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (!json.url || typeof json.url !== "string") {
          throw new Error("El reporte no fue generado correctamente");
        }

        return json;
      })
      .catch((error) => {
        let resultError = parseError(error, "createReporteConsumo");
        console.error(resultError);
        throw resultError;
      });
  };

export { createPostReporteConsumo };
