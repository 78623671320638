import fetch from "isomorphic-fetch";
import { sendMessage } from "./sendMessage";

const services = [
  { id: "originacion.mg-group.net.ar", errorCode: "001" },
  { id: "app.mg-group.com.ar/web", errorCode: "002" },
  { id: "api.mg-group.net.ar/creditrisk", errorCode: "003" },
  { id: "api.mg-group.net.ar/mgs", errorCode: "004" },
  { id: "api.mg-group.net.ar/presigner", errorCode: "005" }
];

export default function(url, options) {
  var retries = 3;
  var retryDelay = 1000;

  if (options && options.retries) {
    retries = options.retries;
  }

  if (options && options.retryDelay) {
    retryDelay = options.retryDelay;
  }

  return new Promise(function(resolve, reject) {
    var wrappedFetch = function(n) {
      fetch(url, options)
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          const service = services.find(x => url.includes(x.id));
          if (n > 0) {
            sendMessage(
              `Problema al comunicarse con el servidor, reintentando (Código: ${
                service ? service.errorCode : "000"
              })`,
              "warning"
            );
            setTimeout(function() {
              wrappedFetch(--n);
            }, retryDelay);
          } else {
            reject(error);
          }
        });
    };
    wrappedFetch(retries);
  });
}
