let createGetPolizas = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk
}) => async () => {
  let request = buildRequestWithAuth("GET");
  try {
    let response = await fetch(
      `${environment.catalogosUrl}/apiclientes/polizas`,
      request
    );
    errorUnlessOk(response);
    return response.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
};

let createGetDetallePoliza = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk
}) => async idPoliza => {
  let request = buildRequestWithAuth("GET");
  try {
    let response = await fetch(
      `${environment.catalogosUrl}/apiclientes/polizas/${idPoliza}`,
      request
    );
    errorUnlessOk(response);
    return response.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
};

let createGetPolizaFile = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
  parseError
}) => idPoliza => {
  let request = buildRequestWithAuth("GET");
  return fetch(
    `${environment.catalogosUrl}/apiclientes/seguros/${idPoliza}/poliza`,
    request
  )
    .then(errorUnlessOk)
    .then(response => response.json())
    .catch(error => {
      let resultError = parseError(error, "createGetPolizaFile");
      console.error(resultError);
      throw resultError;
    });
};

let createGetTarjetaMercosur = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
  parseError
}) => idPoliza => {
  let request = buildRequestWithAuth("GET");
  return fetch(
    `${environment.catalogosUrl}/apiclientes/seguros/${idPoliza}/tarjetamercosur`,
    request
  )
    .then(errorUnlessOk)
    .then(response => response.json())
    .catch(error => {
      let resultError = parseError(error, "createGetTarjetaMercosur");
      console.error(resultError);
      throw resultError;
    });
};

export {
  createGetPolizas,
  createGetDetallePoliza,
  createGetPolizaFile,
  createGetTarjetaMercosur
};
