import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { openMainMenu } from "../topBar/js/actions";
import { logInUser, logOut, sendRecoveryPasswordEmail } from "./js/actions";
import LoginForm from "./LoginForm";

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      logInUser,
      logOut,
      sendRecoveryPasswordEmail,
      openMainMenu: openMainMenu
    },
    dispatch
  );
};

const mapStateToProps = state => ({
  oldLoginReducer: state.oldLoginReducer
});

const LoginFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm);
export default LoginFormContainer;
