import { useEffect, useState } from "react";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useAuth0 } from "componentes/Auth0/useAuth";

const useFeatureFlags = () => {
  const { authState } = useAuth0();
  const ldClient = useLDClient();
  const [ldContext, setLDContext] = useState(null);

  useEffect(() => {
    const newLDContext = authState?.user
      ? {
          kind: "user",
          key: authState.user.email,
          name: authState.user.preferred_username,
          email: authState.user.email,
        }
      : null;

    if (ldClient && newLDContext && newLDContext.key !== ldContext?.key) {
      ldClient.identify(newLDContext);
      setLDContext(newLDContext);
    }
  }, [ldClient, ldContext, authState.user, setLDContext]);
};

export default useFeatureFlags;
