import { createActions, handleActions } from "redux-actions";

const {
  business: {
    codigosPostales: {
      clearCodigosPostales,
      fetchCodigosPostalesInited,
      fetchCodigosPostalesCompleted,
      fetchCodigosPostalesFailed,
    },
  },
} = createActions({
  business: {
    codigosPostales: {
      clearCodigosPostales: () => ({}),
      fetchCodigosPostalesInited: (props) => ({ props }),
      fetchCodigosPostalesCompleted: (
        provinciaId,
        localidad,
        codigosPostales
      ) => ({ provinciaId, localidad, codigosPostales }),
      fetchCodigosPostalesFailed: (error) => ({ error }),
    },
  },
});

const fetchCodigosPostales = (provinciaId, localidad) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchCodigosPostalesInited({ provinciaId, localidad }));
      const response = await services.api.getProvinciasCodigosPostales(
        provinciaId,
        localidad
      );
      dispatch(
        fetchCodigosPostalesCompleted(
          provinciaId,
          localidad,
          response.codigosPostales
        )
      );
    } catch (error) {
      console.error(error);
      dispatch(fetchCodigosPostalesFailed(error));
    }
  };
};

const initialState = {
  codigosPostales: null,
  isLoading: false,
};

const codigoPostalSelectorReducer = handleActions(
  {
    [clearCodigosPostales]: () => ({ ...initialState }),
    [fetchCodigosPostalesInited]: () => {
      return {
        ...initialState,
        isLoading: true,
        codigosPostales: null,
      };
    },
    [fetchCodigosPostalesCompleted]: (state, action) => {
      const { provinciaId, localidad, codigosPostales } = action.payload;
      const codigosPostalesOptions = codigosPostales.map((item) => ({
        value: item,
        label: item,
      }));
      return {
        ...initialState,
        codigosPostales: {
          [`${provinciaId}/${localidad}`]: codigosPostalesOptions,
        },
        isLoading: false,
      };
    },
    [fetchCodigosPostalesFailed]: () => {
      return {
        ...initialState,
        isLoading: false,
      };
    },
  },
  initialState
);

export default codigoPostalSelectorReducer;
export {
  clearCodigosPostales,
  fetchCodigosPostalesInited,
  fetchCodigosPostalesCompleted,
  fetchCodigosPostalesFailed,
  fetchCodigosPostales,
};
