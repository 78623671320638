import React from "react";
import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
  barColor: {
    backgroundColor: theme.palette.custom.accent1Color + "!important",
  },
  loaderColor: {
    backgroundColor: theme.palette.secondary.light + "!important",
  }
}))

const LinearProgressLoading = ({ isLoading, zIndex, position, isFixedToTop, width }) => {
  const classes = useStyles();

  if (isLoading) {
    return (
      <LinearProgress
        color="primary"
        classes={{ colorPrimary: classes.barColor, barColorPrimary: classes.loaderColor }}
        style={{
          zIndex: zIndex ? zIndex : 100,
          position: position ? position : "fixed",
          height: "4px",
          top: isFixedToTop ? 64 : "initial",
          width: width ? width : "100%"
        }}
      />
    );
  } else {
    return null;
  }
}

LinearProgressLoading.propTypes = {
  width: PropTypes.number,
  isLoading: PropTypes.bool,
  position: PropTypes.string,
  isFixedToTop: PropTypes.bool,
};

export default LinearProgressLoading;
