import isNullOrEmpty from "../../../../libs/isNullOrEmpty";
import localRepository from "../../../../localRepository";

export const CODIGOS_POSTALES_INITED = "CODIGOS_POSTALES_INITED";
export const CODIGOS_POSTALES_COMPLETED = "CODIGOS_POSTALES_COMPLETED";
export const CODIGOS_POSTALES_FAILED = "CODIGOS_POSTALES_FAILED";

export const fetchCodigosPostales = (provinciaId, localidad) => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchCodigosPostalesInited());
      let response, codMostUsed;
      if (localidad) {
        response = await services.api.getProvinciasCodigosPostales(
          provinciaId,
          localidad
        );
        codMostUsed = localRepository.mostUsedCodLoc
          .get()
          .filter((cod) => cod.provId === provinciaId && cod.loc === localidad);
      } else {
        response = await services.api.getProvinciasCodigosPostales(provinciaId);
        codMostUsed = localRepository.mostUsedCod
          .get()
          .filter((cod) => cod.provId === provinciaId);
      }

      codMostUsed = codMostUsed.sort((x, y) => y.count - x.count).slice(0, 3);
      dispatch(fetchCodigosPostalesCompleted(response, codMostUsed));
    } catch (error) {
      dispatch(fetchCodigosPostalesFailed(error));
      dispatch(services.errorHandler.checkErrorAction(error));
    }
  };
};

const fetchCodigosPostalesInited = () => {
  return {
    type: CODIGOS_POSTALES_INITED,
  };
};

const fetchCodigosPostalesCompleted = (response, codMostUsed) => {
  let codigosPostales = [];

  if (
    typeof response !== "undefined" &&
    response !== null &&
    response.length !== 0
  ) {
    codigosPostales = response.codigosPostales
      .filter((x) => !isNullOrEmpty(x))
      .map((x) => ({
        value: x,
        label: x,
      }));
    if (codMostUsed.length > 0) {
      codigosPostales = codMostUsed.concat(codigosPostales);
    }
  }

  return {
    type: CODIGOS_POSTALES_COMPLETED,
    payload: {
      codigosPostales,
    },
  };
};

const fetchCodigosPostalesFailed = (error) => {
  return {
    type: CODIGOS_POSTALES_FAILED,
    payload: {
      error,
    },
  };
};
