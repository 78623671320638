let createGetChangeRequest = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
}) => (solicitudId) => {
  let request = buildRequestWithAuth("GET", {});

  return fetch(
    `${environment.originacionUrl}/solicitudes/${solicitudId}/cambios`,
    request
  )
    .then(errorUnlessOk)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

let createPostChangeRequest = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk,
}) => (data) => {
  let request = buildRequestWithAuth("POST", data);

  return fetch(
    `${environment.originacionUrl}/solicitudes/${data.solicitudId}/cambios`,
    request
  )
    .then(errorUnlessOk)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export { createGetChangeRequest, createPostChangeRequest };
