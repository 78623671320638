import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import OutlinedButton from "components/styled/outlinedButton/OutlinedButton";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/ArrowBack";
import ResultMessage from "../shared/layout/ResultMessage";
import MessageViewer from "../../componentes/notifications/MessageViewer";
import { validateEmail } from "../shared/emailField";
import { LinearProgressLoading } from "../shared/progress";
import lang from "../../lang";
import { withRouter } from "react-router-dom";

import useTheme from "@material-ui/core/styles/useTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Fade from "react-reveal/Fade";
import Layout from "../../componentes/auth/Layout";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  header: {
    height: 64,
    backgroundColor: theme.palette.primary.main,
    lineHeight: "64px",
    display: "flex",
    position: "fixed",
    width: "100%",
    marginTop: 0,
  },
  logo: {
    display: "block",
    maxWidth: 80,
    height: "auto",
    margin: "0 auto",
  },
  text: {
    marginTop: "2%",
    color: "#663ea8",
    fontFamily: "Nunito ",
    fontSize: "1.35rem !important",
    fontWeight: "bold !important",
  },
}));

const LoginForm = ({
  location,
  history,
  openMainMenu,
  logOut,
  logInUser,
  sendRecoveryPasswordEmail,
  oldLoginReducer,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [credentials, setCredentials] = useState({
    username: "",
    usernameError: "",
    password: "",
    passwordError: "",
  });
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isRecoveryPassword, setIsRecoveryPassword] = useState(
    location.search.includes("password")
  );
  const [recoverEmailSended, setRecoverEmailSended] = useState(false);

  useEffect(() => {
    if (oldLoginReducer.logIn && oldLoginReducer.user)
      history.push(`/actualizando/success?idEuro=${oldLoginReducer.user.sub}`);
  }, [oldLoginReducer.logIn, oldLoginReducer.user]);

  useEffect(() => {
    openMainMenu(false);
    logOut();
  }, []);

  const onChangeUserName = (event) => {
    const validationEmailResult = validateEmail(credentials.username, true);

    setCredentials({
      ...credentials,
      username: event.target.value,
      usernameError: "",
    });
    setIsEmailValid(validationEmailResult.valid);
  };

  const onChangePassword = (event) => {
    setCredentials({
      ...credentials,
      password: event.target.value,
      passwordError: "",
    });
  };

  const handleFinalizar = () => {
    setIsRecoveryPassword(false);
  };

  const handleLogin = (event) => {
    if (event) event.preventDefault();

    let validation = validate();
    let isLoading = oldLoginReducer.isLoading;

    if (!isLoading && validation.usernameValid && validation.passwordValid)
      logInUser(credentials);
    else
      setCredentials({
        ...credentials,
        usernameError: validation.usernameValid ? "" : "*",
        passwordError: validation.passwordValid ? "" : "*",
      });
  };

  const validate = () => {
    let usernameValid = credentials.username && credentials.username.length > 0;
    //agregar validacion this.state.isEmailValid

    let passwordValid = credentials.password && credentials.password.length > 0;

    return { usernameValid, passwordValid };
  };

  const onKeyPress = (event) => {
    let keyCode = event.which || event.keyCode;

    if (keyCode === 13) handleLogin();
  };

  const handleOpenRecoveryPasswordDialog = (isRecoveryPassword) => () => {
    setRecoverEmailSended(false);
    setIsRecoveryPassword(isRecoveryPassword);
  };

  const handleSendRecoveryPassword = (event) => {
    if (event) event.preventDefault();

    let validation = validate();

    if (!oldLoginReducer.isLoading && validation.usernameValid) {
      setRecoverEmailSended(true);
      sendRecoveryPasswordEmail(credentials.username);
    }
  };

  useEffect(() => {
    isRecoveryPassword &&
      setRecoverEmailSended(oldLoginReducer.error ? false : recoverEmailSended);
  }, [isRecoveryPassword, setRecoverEmailSended]);

  return (
    <div data-testid={"login-form"}>
      <MessageViewer />
      {!isRecoveryPassword && (
        <Layout>
          <Card
            style={{
              maxWidth: 450,
              margin: "auto",
              height: "70vh",
              padding: 20,
              backgroundColor: "#fff",
              borderRadius: 8,
              boxShadow: "rgba(0, 0, 0, 0.12) 7px 12px 20px 5px",
            }}
          >
            <Typography className={classes.text}>
              Ingresá tu Email y contraseña anteriores
            </Typography>
            <CardContent>
              <TextField
                autoFocus
                disabled={oldLoginReducer.isLoading}
                label={lang.loginForm.emailLabel.toUpperCase()}
                fullWidth
                value={credentials.username}
                onChange={onChangeUserName}
                onKeyPress={onKeyPress}
                required
                type="email"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{
                  marginBottom: 16,
                }}
              />
              <TextField
                disabled={oldLoginReducer.isLoading}
                type="password"
                label={lang.loginForm.passwordLabel.toUpperCase()}
                fullWidth
                value={credentials.password}
                onChange={onChangePassword}
                onKeyPress={onKeyPress}
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </CardContent>
            <CardActions>
              <OutlinedButton
                labelText={lang.loginForm.submitLabel.toUpperCase()}
                onClick={handleLogin}
                disabled={oldLoginReducer.isLoading}
                fullWidth
              />
            </CardActions>
            <span
              style={{
                cursor: "pointer",
                color: "#337ab7",
                fontSize: 14,
                display: "block",
                textAlign: "center",
                marginTop: 15,
              }}
              onClick={
                !oldLoginReducer.isLoading
                  ? handleOpenRecoveryPasswordDialog(true)
                  : undefined
              }
            >
              {lang.loginForm.forgotPasswordLabel}
            </span>
          </Card>
        </Layout>
      )}
      {isRecoveryPassword && (
        <div>
          <div className={classes.header}>
            <div>
              <IconButton
                onClick={
                  !oldLoginReducer.isLoading
                    ? handleOpenRecoveryPasswordDialog(false)
                    : undefined
                }
                style={{ color: "#FFF" }}
              >
                <BackIcon />
              </IconButton>
            </div>
            <div style={{ textAlign: "center", flex: 1 }}>
              <span
                style={{
                  color: "#fff",
                  fontSize: 16,
                }}
              >
                Olvidé mi contraseña
              </span>
            </div>
            <div style={{ textAlign: "right", width: 48 }} />
          </div>
          {(!recoverEmailSended || oldLoginReducer.isLoading) && (
            <div style={{ display: "block", paddingTop: 64 }}>
              <div style={{ padding: 20, paddingTop: 0 }}>
                <Fade>
                  <LinearProgressLoading
                    isLoading={oldLoginReducer.isLoading}
                    fixedHeight={true}
                  />
                  <TextField
                    value={credentials.username}
                    disabled={oldLoginReducer.isLoading}
                    label={lang.loginForm.emailLabel.toUpperCase()}
                    fullWidth
                    autoFocus
                    onChange={onChangeUserName}
                    required
                    type="email"
                    style={{
                      marginTop: 16,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <OutlinedButton
                    labelText={lang.loginForm.recoveryPasswordLabel.toUpperCase()}
                    disabled={!isEmailValid}
                    onClick={handleSendRecoveryPassword}
                    fullWidth
                  />
                </Fade>
              </div>
            </div>
          )}
          {recoverEmailSended && !oldLoginReducer.isLoading && (
            <div style={{ display: "block", paddingTop: 64 }}>
              <div style={{ padding: 20, display: "block" }}>
                <Fade>
                  <ResultMessage
                    color={theme.palette.custom.success7Color}
                    backgroundColor={theme.palette.custom.success6Color}
                    borderColor={theme.palette.custom.success8Color}
                    fontIcon={"done"}
                    title={"Email enviado"}
                    message={
                      <React.Fragment>
                        Te hemos enviado un correo para que puedas cambiar tu
                        contraseña. Seguí las instrucciones indicadas en el
                        mismo.
                        <br />
                        <br />
                      </React.Fragment>
                    }
                  />
                  <OutlinedButton
                    labelText="FINALIZAR"
                    onClick={handleFinalizar}
                    fullWidth
                  />
                </Fade>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default withRouter(LoginForm);
