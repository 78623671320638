import {
  FETCHLOCALIDADES_INITED,
  FETCHLOCALIDADES_COMPLETED,
  FETCHLOCALIDADES_FAILED
} from "./actions";

const initialState = {
  isLoading: false,
  localidades: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHLOCALIDADES_INITED:
      return {
        ...state,
        localidades: [],
        isLoading: true
      };
    case FETCHLOCALIDADES_FAILED:
      return {
        ...state,
        localidades: [],
        isLoading: false
      };
    case FETCHLOCALIDADES_COMPLETED:
      return {
        ...state,
        localidades: [...action.payload.localidades],
        isLoading: false
      };
    default:
      return state;
  }
};

export default reducer;
