import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import EmailValidator from "email-validator";

import Typography from "@material-ui/core/Typography";

import keyIcon from "images/auth/key.svg";

import SocialButton from "componentes/shared/SocialButton";
import TextField from "@material-ui/core/TextField";

import WaveLayout from "./WaveLayout";

import {
  signInInit,
  signInWithEmail,
  signInWithEmailConfirmed,
} from "./actions";

import { useAuth0 } from "../Auth0/useAuth";

const useStyles = makeStyles({
  otherAccounts: {
    fontSize: 14,
    color: "#757575",
    textDecoration: "underline !important",
    lineHeight: 1.36,
  },
});

const useReduxSelector = () =>
  useSelector((state) => ({
    isLoading: state.authReducer.isLoading,
    isConfirming: state.authReducer.isConfirming,
  }));

const SigninWithEmail = ({ onChangeToSocialNetworks }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { isLoading, isConfirming } = useReduxSelector();

  const [email, setEmail] = useState("");
  const [emailCode, setEmailCode] = useState("");

  const { loginPasswordlessEmail, confirmPasswordlessEmail } = useAuth0();

  useEffect(() => {
    dispatch(signInInit());
  }, []);

  useEffect(() => {
    setEmailCode("");
  }, [isConfirming]);

  return (
    <React.Fragment>
      {!isConfirming && (
        <WaveLayout title={"Ingresá con tu email"} isLoading={isLoading}>
          <TextField
            label="Email"
            disabled={isConfirming}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            fullWidth={true}
            style={{ width: "295px" }}
          />
          <div style={{ height: 40 }} />
          <SocialButton
            disabled={email.length === 0 || !EmailValidator.validate(email)}
            onClick={() =>
              dispatch(signInWithEmail(email, loginPasswordlessEmail))
            }
            label={"Entrar"}
            icon={keyIcon}
            backgroundColor={"#4cbfd6"}
            backgroundColorIcon={"#33aec4"}
          />
          <div style={{ height: 60 }} />
          <a
            href={"javascript:void(0)"}
            className={classes.otherAccounts}
            onClick={onChangeToSocialNetworks}
          >
            Volver
          </a>
        </WaveLayout>
      )}
      {isConfirming && (
        <WaveLayout
          title={"Ingresá el código que te\n\nenviamos a tu correo"}
          isLoading={isLoading}
        >
          <TextField
            label="Código"
            fullWidth={true}
            value={emailCode}
            onChange={(event) => setEmailCode(event.target.value)}
            style={{ width: "295px" }}
          />
          <div style={{ height: 40 }} />
          <SocialButton
            disabled={emailCode.length === 0}
            onClick={() =>
              dispatch(
                signInWithEmailConfirmed(
                  {
                    email,
                    emailCode,
                  },
                  confirmPasswordlessEmail
                )
              )
            }
            label={"Confirmar"}
            icon={keyIcon}
            backgroundColor={"#4cbfd6"}
            backgroundColorIcon={"#33aec4"}
          />
          <div style={{ height: 60 }} />
          <Typography
            variant="body2"
            style={{ fontWeight: "bold", color: "#616161" }}
          >
            Enviado a {email}
          </Typography>
          <div style={{ height: 10 }} />
          <Typography
            variant="body2"
            style={{ fontSize: "12px", color: "#757575" }}
          >
            Si no te llega el mail al cabo de unos minutos, revisá la carpeta de
            Correo no deseado (Spam)
          </Typography>
          <div style={{ height: 20 }} />
          <a
            href={"javascript:void(0)"}
            className={classes.otherAccounts}
            onClick={onChangeToSocialNetworks}
          >
            ¿No lo recibiste?
          </a>
        </WaveLayout>
      )}
    </React.Fragment>
  );
};

export default SigninWithEmail;
