import browserLocale from 'browser-locale';
import localRepository from '../localRepository';

import langEs from './lang-es';

let locale = typeof browserLocale() === "string" ? browserLocale() : "es";

locale = localRepository.hasCurrentLang.get() ? localRepository.currentLang.get() : locale;

let lang = langEs; // default value

if (locale.toLowerCase().startsWith("es")) {
  lang = langEs;
  localRepository.currentLang.set("es");
}
else if (locale.toLowerCase().startsWith("en"))
{
  lang = langEs; // example.
  localRepository.currentLang.set("es");
}

export default lang;