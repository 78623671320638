// configuration dependencies
import environment from "../environment";
import localRepository from "../localRepository";
import fetchRetry from "../libs/fetch-retry";

import {
  parseError,
  errorUnlessOk,
  buildRequest,
  createBuildRequestWithAuth,
} from "./utils";

import {
  createPostChangeRequest,
  createGetChangeRequest,
} from "./pedidoCambioApiFactories";

import { createGetTermsAndConditions } from "./tycApiFactories";

import {
  getProvinciasArgentinas,
  createGetProvinciasCodigosPostales,
  createGetProvinciasLocalidades,
} from "./provinciasApiFactories";

import { createGetCanales } from "./canalesApiFactories";

import {
  createPostCapturaAuto,
  createPostCapturaAutoAsync,
  createGetCapturaAutoResult,
} from "./autoApiFactories";

import {
  createGetVehiclesMarcas,
  createGetVehiclesVersiones,
  createGetVehiclesDetalle,
  createGetVehiclesPrice,
  createGetVehicleReferencePrice,
} from "./vehiclesApiFactories";

import {
  createGetContacto,
  createPostContacto,
  createPostContactoConsumo,
  createPostEmailConsumo,
} from "./contactoApiFactories";

import { createPostRegisterPushToken } from "./notificationsApiFactories";

import { createGetCalculadorResult } from "./calculadorApiFactories";
import { createGetCalculadorConsumoResult } from "./calculadorConsumoApiFactories";

import {
  createPostAgregarCodeudor,
  createPostAgregarConyugeCodeudor,
  createPostAgregarConyuge,
  createPostSolicitudNueva,
  createPostContinuarSolicitud,
  createPostSolicitudIngresos,
  createPostSolicitudDomicilioTitular,
  createGetConfiguracionContactos,
  createPostSolicitudAuto,
  createPostSolicitudPreferenciaVehiculo,
  createPostSolicitudImporte,
  createGetSolicitudSegurosCotizaciones,
  createPostSolicitudSeguroCotizacion,
  createGetEstadoCalificacion,
  createGetOfertas,
  createPostSolicitudSeleccionOferta,
  createPostSolicitudCerrarSinOferta,
  createGetSolicitudes,
  createGetSolicitudById,
  createGetSolicitudSegurosInicio,
  createGetSolicitudSegurosResult,
  createGetPlazosPrestamo,
  createGetEstadoSolicitud,
  createPostSolicitarConfeccionPrenda,
} from "./solicitudesApiFactories";

import {
  createPostSolicitudConsumoNueva,
  createPostSolicitudConsumoDomicilio,
  createPostDictamenConsumo,
  createGetOfertasConsumo,
  createPostSolicitudConsumoSeleccionOferta,
  createUploadTicketConsumo,
  createInicioFirma,
  createVerificarFirma,
  createGetSolicitudConsumoById,
  createReenviarSMS,
} from "./solicitudesConsumoApiFactories";

import { createPostReporteConsumo } from "./reporteConsumo";

import {
  createGetTareasSolicitud,
  createGetTareaSolicitud,
  createPostTarea,
  createGetFetchArchivoTarea,
  createGetFetchArchivoTareaThumbnail,
  createPostArchivoTarea,
  createDeleteArchivoTarea,
  createPostTareaConyuge,
  createPostTareaCodeudor,
  createPostTareaTelefono,
} from "./tareasApiFactories";

import { createGetIdentidad } from "./creditRiskApiFactories";

import {
  createGetSegurosCotizaciones,
  createPostSegurosCotizar,
  createPostSegurosQuieroEstaPoliza,
  createPostSegurosQuieroQueMeContacten,
} from "./segurosFactories";

import {
  createPostSignupAgencia,
  createPostSingupAgenciaConfirmacion,
  createPostAgenciaUserPassword,
} from "./signupAgenciasFactories";

import { createPostSignupClienteFinal } from "./signupClienteFInalFactories";
import { createPostSignupProductor } from "./signupProductorFactories";

import {
  createPostRecoverPassword,
  createSessionLogin,
  createSessionSendRecoveryPasswordEmail,
  createGetPublicIp,
  createPostVincularOperaciones,
} from "./userApiFactories";

import {
  createGetAceptacion,
  createPostAceptarTerminos,
} from "./terminosYCondicionesApiFactories";
import { createGetUserExists } from "./authApiFactories";

import {
  createGetCreditosVigentes,
  createGetDetalleCredito,
  createGetCuotasCredito,
  createGetCuponesCredito,
  createGetComprobantesPago,
  createGetComprobanteCreditoAlDia,
  createGetCuponPagoSaldo,
  createGetNotaCancelacion,
  createPostInformarPago,
} from "./creditosApiFactories";

import {
  createGetPolizas,
  createGetDetallePoliza,
  createGetPolizaFile,
  createGetTarjetaMercosur,
} from "./segurosApiFactories";

import { createPostQuieroQueMeContacten } from "./quieroQueMeContactenApiFactories";

// dependecies injection

const buildRequestWithAuth = createBuildRequestWithAuth({ localRepository });

const sessionLogin = createSessionLogin({
  fetch: window.fetch,
  environment,
  buildRequest,
  errorUnlessOk,
});
const sessionSendRecoveryPasswordEmail = createSessionSendRecoveryPasswordEmail(
  { fetch: window.fetch, environment, buildRequest, errorUnlessOk }
);

let [
  getCanales,
  postCapturaAuto,
  postCapturaAutoAsync,
  getCapturaAutoResult,
  getProvinciasCodigosPostales,
  getProvinciasLocalidades,
  getSolicitudSegurosCotizaciones,
  getIdentidad,
  postSolicitudNueva,
  postSolicitudConsumoNueva,
  postSolicitudConsumoDomicilio,
  postDictamenConsumo,
  getOfertasConsumo,
  postSolicitudConsumoSeleccionOferta,
  uploadTicketConsumo,
  inicioFirma,
  postContinuarSolicitud,
  postSolicitudIngresos,
  postSolicitudDomicilioTitular,
  getConfiguracionContactos,
  postSolicitudImporte,
  postSolicitudAuto,
  postSolicitudPreferenciaVehiculo,
  postSolicitudSeguroCotizacion,
  getEstadoCalificacion,
  getOfertas,
  postSolicitudSeleccionOferta,
  postSolicitudCerrarSinOferta,
  getSolicitudes,
  getSolicitudById,
  getSolicitudConsumoById,
  verificarFirma,
  reenviarSMS,
  getTermsAndConditions,
  getCalculadorResult,
  getCalculadorConsumoResult,
  getContactoOffice,
  postContacto,
  postContactoConsumo,
  postEmailConsumo,
  postReporteConsumo,
  postRegisterPushToken,
  getTareasSolicitud,
  getTareaSolicitud,
  postTarea,
  fetchArchivoTarea,
  fetchArchivoTareaThumbnail,
  postArchivoTarea,
  deleteArchivoTarea,
  postTareaConyuge,
  postTareaCodeudor,
  postTareaTelefono,
  postChangeRequest,
  postSignupAgencia,
  getPublicIp,
  postSingupAgenciaConfirmacion,
  postAgenciaUserPassword,
  postRecoverPassword,
  getchangeRequests,
  getSegurosCotizaciones,
  postSegurosCotizar,
  postSegurosQuieroEstaPoliza,
  patchSegurosQuieroQueMeContacten,
  getSolicitudSegurosInicio,
  getSolicitudSegurosResult,
  postAgregarConyuge,
  postAgregarConyugeCodeudor,
  postAgregarCodeudor,
  getAceptacion,
  postAceptarTerminos,
  getPlazosPrestamo,
  getEstadoSolicitud,
  getUserExists,
  getCreditosVigentes,
  getDetalleCredito,
  getCuotasCredito,
  getCuponesCredito,
  getComprobantesPago,
  getComprobanteCreditoAlDia,
  getCuponPagoSaldo,
  getNotaCancelacion,
  getPolizas,
  getDetallePoliza,
  getTarjetaMercosur,
  postInformarPago,
  getPolizaFile,
  postSignupClienteFinal,
  postSignupProductor,
  postVincularOperaciones,
  postQuieroQueMeContacten,
  getVehiclesMarcas,
  getVehiclesVersiones,
  getVehiclesDetalle,
  getVehiclesPrice,
  getVehicleReferencePrice,
  postSolicitarConfeccionPrenda,
] = [
  createGetCanales,
  createPostCapturaAuto,
  createPostCapturaAutoAsync,
  createGetCapturaAutoResult,
  createGetProvinciasCodigosPostales,
  createGetProvinciasLocalidades,
  createGetSolicitudSegurosCotizaciones,
  createGetIdentidad,
  createPostSolicitudNueva,
  createPostSolicitudConsumoNueva,
  createPostSolicitudConsumoDomicilio,
  createPostDictamenConsumo,
  createGetOfertasConsumo,
  createPostSolicitudConsumoSeleccionOferta,
  createUploadTicketConsumo,
  createInicioFirma,
  createPostContinuarSolicitud,
  createPostSolicitudIngresos,
  createPostSolicitudDomicilioTitular,
  createGetConfiguracionContactos,
  createPostSolicitudImporte,
  createPostSolicitudAuto,
  createPostSolicitudPreferenciaVehiculo,
  createPostSolicitudSeguroCotizacion,
  createGetEstadoCalificacion,
  createGetOfertas,
  createPostSolicitudSeleccionOferta,
  createPostSolicitudCerrarSinOferta,
  createGetSolicitudes,
  createGetSolicitudById,
  createGetSolicitudConsumoById,
  createVerificarFirma,
  createReenviarSMS,
  createGetTermsAndConditions,
  createGetCalculadorResult,
  createGetCalculadorConsumoResult,
  createGetContacto,
  createPostContacto,
  createPostContactoConsumo,
  createPostEmailConsumo,
  createPostReporteConsumo,
  createPostRegisterPushToken,
  createGetTareasSolicitud,
  createGetTareaSolicitud,
  createPostTarea,
  createGetFetchArchivoTarea,
  createGetFetchArchivoTareaThumbnail,
  createPostArchivoTarea,
  createDeleteArchivoTarea,
  createPostTareaConyuge,
  createPostTareaCodeudor,
  createPostTareaTelefono,
  createPostChangeRequest,
  createPostSignupAgencia,
  createGetPublicIp,
  createPostSingupAgenciaConfirmacion,
  createPostAgenciaUserPassword,
  createPostRecoverPassword,
  createGetChangeRequest,
  createGetSegurosCotizaciones,
  createPostSegurosCotizar,
  createPostSegurosQuieroEstaPoliza,
  createPostSegurosQuieroQueMeContacten,
  createGetSolicitudSegurosInicio,
  createGetSolicitudSegurosResult,
  createPostAgregarConyuge,
  createPostAgregarConyugeCodeudor,
  createPostAgregarCodeudor,
  createGetAceptacion,
  createPostAceptarTerminos,
  createGetPlazosPrestamo,
  createGetEstadoSolicitud,
  createGetUserExists,
  createGetCreditosVigentes,
  createGetDetalleCredito,
  createGetCuotasCredito,
  createGetCuponesCredito,
  createGetComprobantesPago,
  createGetComprobanteCreditoAlDia,
  createGetCuponPagoSaldo,
  createGetNotaCancelacion,
  createGetPolizas,
  createGetDetallePoliza,
  createGetTarjetaMercosur,
  createPostInformarPago,
  createGetPolizaFile,
  createPostSignupClienteFinal,
  createPostSignupProductor,
  createPostVincularOperaciones,
  createPostQuieroQueMeContacten,
  createGetVehiclesMarcas,
  createGetVehiclesVersiones,
  createGetVehiclesDetalle,
  createGetVehiclesPrice,
  createGetVehicleReferencePrice,
  createPostSolicitarConfeccionPrenda,
].map((factory) =>
  factory({
    fetch: fetchRetry,
    environment,
    buildRequestWithAuth,
    errorUnlessOk,
    parseError,
    localRepository,
  })
);

export {
  sessionLogin,
  sessionSendRecoveryPasswordEmail,
  getProvinciasArgentinas,
  getCanales,
  postCapturaAuto,
  postCapturaAutoAsync,
  getCapturaAutoResult,
  getProvinciasCodigosPostales,
  getProvinciasLocalidades,
  getSolicitudSegurosCotizaciones,
  getIdentidad,
  postSolicitudNueva,
  postSolicitudConsumoNueva,
  postSolicitudConsumoDomicilio,
  postDictamenConsumo,
  getOfertasConsumo,
  createPostSolicitudConsumoSeleccionOferta,
  postSolicitudConsumoSeleccionOferta,
  uploadTicketConsumo,
  postContinuarSolicitud,
  postSolicitudIngresos,
  postSolicitudDomicilioTitular,
  getConfiguracionContactos,
  postSolicitudAuto,
  postSolicitudPreferenciaVehiculo,
  postSolicitudImporte,
  postSolicitudSeguroCotizacion,
  getEstadoCalificacion,
  getOfertas,
  postSolicitudSeleccionOferta,
  postSolicitudCerrarSinOferta,
  getSolicitudes,
  getSolicitudById,
  getSolicitudConsumoById,
  inicioFirma,
  verificarFirma,
  reenviarSMS,
  getTermsAndConditions,
  getCalculadorResult,
  getCalculadorConsumoResult,
  getContactoOffice,
  postContacto,
  postContactoConsumo,
  postEmailConsumo,
  postReporteConsumo,
  postRegisterPushToken,
  getTareasSolicitud,
  getTareaSolicitud,
  postTarea,
  fetchArchivoTarea,
  fetchArchivoTareaThumbnail,
  postArchivoTarea,
  deleteArchivoTarea,
  postTareaConyuge,
  postTareaCodeudor,
  postTareaTelefono,
  postChangeRequest,
  postSignupAgencia,
  getPublicIp, //revisar - no está en uso
  postSingupAgenciaConfirmacion,
  postAgenciaUserPassword,
  postRecoverPassword,
  getchangeRequests,
  getSegurosCotizaciones,
  postSegurosCotizar,
  postSegurosQuieroEstaPoliza,
  patchSegurosQuieroQueMeContacten,
  getSolicitudSegurosInicio,
  getSolicitudSegurosResult,
  postAgregarConyuge,
  postAgregarConyugeCodeudor,
  postAgregarCodeudor,
  getAceptacion,
  postAceptarTerminos,
  getPlazosPrestamo,
  getEstadoSolicitud,
  buildRequestWithAuth,
  getUserExists,
  getCreditosVigentes,
  getDetalleCredito,
  getCuotasCredito,
  getCuponesCredito,
  getComprobantesPago,
  getComprobanteCreditoAlDia,
  getCuponPagoSaldo,
  getNotaCancelacion,
  getPolizas,
  getDetallePoliza,
  getTarjetaMercosur,
  postInformarPago,
  getPolizaFile,
  postSignupClienteFinal,
  postSignupProductor,
  postVincularOperaciones,
  postQuieroQueMeContacten,
  getVehiclesMarcas,
  getVehiclesVersiones,
  getVehiclesDetalle,
  getVehiclesPrice,
  getVehicleReferencePrice,
  postSolicitarConfeccionPrenda,
};
