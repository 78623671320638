import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "componentes/Auth0/useAuth";
import loadable from "@loadable/component";

const ClienteFinalRoutes = loadable(() =>
  import("components/app/clienteFinal/Routes.js")
);
const AgencieroRoutes = loadable(() =>
  import("components/app/agenciero/Routes.js")
);
const InternoRoutes = loadable(() =>
  import("components/app/interno/Routes.js")
);
const ProductorRoutes = loadable(() =>
  import("components/app/productor/Routes.js")
);
const GestorRoutes = loadable(() => import("components/app/gestor/Routes.js"));
const VendedorRoutes = loadable(() =>
  import("components/app/vendedor/Routes.js")
);
const SupervisorRoutes = loadable(() =>
  import("components/app/supervisor/Routes.js")
);

const useReduxSelector = () =>
  useSelector((state) => ({
    isOnNativeApp: state.nativeMessagesReducer.isOnNativeApp,
    deepLinkData: state.nativeMessagesReducer.deepLinkData,
  }));

const Routes = () => {
  const { authState } = useAuth0();
  const history = useHistory();
  const { isOnNativeApp, deepLinkData } = useReduxSelector();
  const currentUserType = authState.user.userType;

  useEffect(() => {
    if (!isOnNativeApp || !deepLinkData) return;

    const deepLink = JSON.parse(deepLinkData);
    if (deepLink.$deeplink_path) history.push(deepLink.$deeplink_path);
  }, [isOnNativeApp, deepLinkData]);

  return (
    <>
      {currentUserType === "ClienteFinal" && <ClienteFinalRoutes />}
      {currentUserType === "Agenciero" && <AgencieroRoutes />}
      {currentUserType === "Interno" && <InternoRoutes />}
      {currentUserType === "Productor" && <ProductorRoutes />}
      {currentUserType === "Gestor" && <GestorRoutes />}
      {currentUserType === "Vendedor" && <VendedorRoutes />}
      {currentUserType === "Supervisor" && <SupervisorRoutes />}
    </>
  );
};

export default Routes;
