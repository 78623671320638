import { alpha } from "@material-ui/core/styles/colorManipulator";
import createTheme from "@material-ui/core/styles/createTheme";
import pxToRem from "libs/pxToRem";
import Grey from "@material-ui/core/colors/grey";

const purple1 = "hsl(263, 46%, 45%)";
const purple2 = "hsl(260, 44%, 45%)";
const purple3 = "hsl(263, 45%, 35%)";
const purple3Emphasized = "hsl(263, 45%, 67.5%)";
const purple4 = "hsl(231, 44%, 94%)";
const purple6 = "#423d5c";
const purple7 = "hsl(241, 46%, 65%)";
const purple8 = "hsla(240, 40%, 99%, 1)";
const purple9 = "hsla(240, 58%, 85%, 0.30)";
const purple10 = "hsl(256, 22%, 43%)";
const purple11 = "hsla(231, 42%, 95%, 0.98)";

const prendo2 = "hsl(259, 96%, 56%)";
const prendo3 = "hsl(183, 100%, 46%)";
const prendo4 = "hsl(236, 21%, 70%)";

const cyan1 = "hsl(190, 63%, 57%)";
const cyan1Emphasized = "hsl(190, 69%, 68%)";
const cyan2 = "hsl(190, 52%, 65%)";
const cyan3 = "hsl(190, 63%, 85%)";
const cyan4 = "hsl(190, 84%, 42%)";

const red1 = "hsl(348, 100%, 35%)";
const red2 = "hsl(0, 100%, 94%)";
const red3 = "hsl(0, 52%, 29%)";
const red4 = "hsla(0, 100%, 94%, 0.36)";

const grey5 = "#5b5d60";
const grey15 = "hsl(0, 0%, 93%)";
const grey7 = "hsl(0, 0%, 91%)";
const grey2 = "hsl(0, 0%, 42%)";
const disabledBtnLinkColor = Grey[400];
const green6 = "hsla(111, 47%, 94%, 0.33)";
const green7 = "hsl(173, 45%, 24%)";
const green8 = "hsl(138, 70%, 87%)";

const mediaBreakpoints = {
  smallMobile: "@media (max-width: 321px)",
  mobile: "@media (max-width: 1023px)",
  desktop: "@media (min-width: 1024px)",
};
const fontFamily = ["Nunito", "Helvetica", "Arial", "sans-serif"].join(",");
const theme = createTheme({
  palette: {
    primary: {
      light: purple1,
      main: purple2,
      dark: purple3,
      contrastText: purple4,
      darkEmphasized: purple3Emphasized,
    },
    secondary: {
      light: cyan3,
      main: cyan2,
      dark: cyan1,
      darkEmphasized: cyan1Emphasized,
      active: cyan4,
    },
    prendo: {
      light: prendo3,
      main: prendo2,
      dark: purple3,
      contrastText: prendo4,
    },
    custom: {
      veryLightRed: red4,
      textColor: alpha(purple6, 0.9),
      logColor: Grey[500],
      cardContentColor: Grey[700],
      tableHeaderColor: purple7,
      accent1Color: cyan1,
      white: "#ffffff",
      errorColor: red1,
      error2Color: red2,
      error3Color: red3,
      unreadColor: grey5,
      tableRowColor: purple8,
      tableTextColor: Grey[600],
      primary4Color: purple4,
      grey15,
      grey7,
      grey2,
      disabledBtnLinkColor,
      success6Color: green6,
      success7Color: green7,
      success8Color: green8,
    },
    chip: {
      backgroundColor: purple11,
      borderColor: purple9,
      disabledColor: alpha(purple6, 0.6),
    },
  },
  typography: {
    fontFamily: fontFamily,
    h1: {
      fontSize: pxToRem(44),
      lineHeight: 1.3,
      fontWeight: 800,
      marginBottom: pxToRem(12.8),
    },
    h3: {
      fontSize: pxToRem(28),
      fontWeight: 600,
      lineHeight: 1.5,
    },
  },
  breakpoints: {
    mobile: "@media (max-width: 1023px)",
    desktop: "@media (min-width: 1024px)",
  },
  overrides: {
    MuiBottomNavigationAction: {
      root: {
        transition: "all 0.5s ease",
        width: "initial",
        minWidth: "initial",
        paddingLeft: 0,
        paddingRight: 0,
        borderBottom: `${pxToRem(4)} solid transparent`,
        "&$selected": {
          borderBottom: `${pxToRem(4)} solid ${purple1}`,
        },
      },
      label: {
        fontSize: `${pxToRem(10.88)} !important`,
        fontWeight: 800,
        lineHeight: 1.36,
        marginTop: 3,
        letterSpacing: pxToRem(0.22),
      },
    },
    MuiOutlinedInput: {
      root: {
        "&$focused": {
          "& fieldset": {
            borderColor: `${purple1} !important`,
          },
        },
        "&:hover fieldset": {
          borderColor: `${Grey[500]} !important`,
        },
        "& fieldset": {
          borderColor: Grey[500],
        },
      },
      input: {
        caretColor: purple1,
        padding: pxToRem(17),
        fontFamily: fontFamily,
        fontSize: pxToRem(16),
        fontWeight: 500,
        lineHeight: 1.25,
        letterSpacing: pxToRem(0.15),
        color: Grey[800],
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 17px) scale(1)",
        color: Grey[500],
        fontSize: pxToRem(16),
        fontWeight: 500,
        lineHeight: 1.25,
        letterSpacing: pxToRem(0.15),
        "&$focused": { color: purple1 },
      },
      root: {
        "&$focused": { color: purple1 },
      },
    },
    MuiInput: {
      input: {
        fontFamily: fontFamily,
        fontSize: "1.25rem",
        fontWeight: 600,
        lineHeight: 1.35,
        letterSpacing: pxToRem(0.19),
        color: Grey[800],
        caretColor: purple1,
        "&::placeholder": {
          color: Grey[400],
          opacity: 1,
        },
      },
      underline: {
        "&:before": {
          borderBottom: `${pxToRem(1)} solid ${Grey[500]}`,
        },
        "&:after": {
          borderBottom: `${pxToRem(2)} solid ${purple1}`,
          transform: `scaleX(0)`,
        },
        "&$focused:after": {
          borderBottom: `${pxToRem(2.5)} solid ${purple1}`,
          transition: `all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms`,
          transform: `scaleX(1)`,
        },
        "&:hover:not(.Mui-disabled):before": {
          borderBottom: `${pxToRem(1)} solid ${Grey[500]}`,
        },
      },
    },
    MuiChip: {
      root: {
        color: alpha(purple6, 0.9),
      },
      deleteIcon: {
        fill: purple10,
        margin: "4px 4px 4px -2px",
        width: "24px",
        height: "24px",
      },
    },
    MuiButton: {
      label: {
        fontFamily: fontFamily,
      },
    },
    MuiButtonBase: {
      root: {
        fontFamily: fontFamily,
      },
    },
  },
});

export default theme;
export { mediaBreakpoints };
