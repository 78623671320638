import { createActions, handleActions } from "redux-actions";
import { tareaEnviadaParaAnalizar } from "../js/actions";
export const {
  setCodeudorComplete,
  setConyugeCodeudorComplete,
  clearTareaCodeudor,
  submitTareaCodeudorCompleted,
  submitTareaCodeudorInited,
  submitTareaCodeudorFailed
} = createActions(
  "SET_CODEUDOR_COMPLETE",
  "SET_CONYUGE_CODEUDOR_COMPLETE",
  "CLEAR_TAREA_CODEUDOR",
  "SUBMIT_TAREA_CODEUDOR_COMPLETED",
  "SUBMIT_TAREA_CODEUDOR_INITED",
  "SUBMIT_TAREA_CODEUDOR_FAILED"
);

export const submitTareaCodeudor = (
  solicitudId,
  tarea,
  codeudor,
  conyuge,
  comentario
) => {
  return async (dispatch, getState, services) => {
    try {
      const codeudorData = constructCodeudorData(codeudor);
      let tareaUpdated = JSON.parse(JSON.stringify(tarea));
      const conyugeData = constructConyugeData(
        codeudor ? codeudor.estadoCivil.value : null,
        conyuge
      );
      let data = constructData(codeudor, conyuge);

      const request = {
        sequence: tarea.sequence,
        comentario,
        codeudor: codeudorData,
        conyuge: conyugeData,
        Data: data
      };
      if (comentario) {
        tareaUpdated.log.push({ comentario: comentario, usrExterno: true });
      }
      tareaUpdated.estado = "Para_Analizar";
      tareaUpdated.data = data;
      dispatch(submitTareaCodeudorInited());
      await services.api.postTareaCodeudor(solicitudId, request);
      dispatch(tareaEnviadaParaAnalizar(tareaUpdated));
      dispatch(submitTareaCodeudorCompleted());
    } catch (error) {
      console.error(error);
      dispatch(submitTareaCodeudorFailed(error));
    }
  };
};

const TareaCodeudorReducer = handleActions(
  {
    [submitTareaCodeudorInited]: (state) => {
      return {
        ...state,
        submitIsLoading: true
      };
    },
    [submitTareaCodeudorCompleted]: (state) => {
      return {
        ...state,
        submitIsLoading: false
      };
    },
    [submitTareaCodeudorFailed]: (state) => {
      return {
        ...state,
        submitIsLoading: false
      };
    },
    [setCodeudorComplete]: (state, action) => {
      return {
        ...state,
        codeudor: action.payload
      };
    },
    [setConyugeCodeudorComplete]: (state, action) => {
      return {
        ...state,
        conyuge: action.payload
      };
    },
    [clearTareaCodeudor]: () => {
      return {
        codeudor: null,
        conyuge: null
      };
    }
  },
  {
    codeudor: null,
    conyuge: null,
    submitIsLoading: false
  }
);

export default TareaCodeudorReducer;

const constructCodeudorData = codeudor => {
  if (codeudor && codeudor.complete) {
    return {
      Apellido: codeudor.apellidos,
      Nombre: codeudor.nombres,
      Documento: codeudor.dni,
      Cuil: codeudor.cuil,
      Sexo: codeudor.sexo.value,
      FechaNacimiento: codeudor.fechaNacimiento,
      Ingreso: codeudor.ingresos,
      ActividadDeclarada: codeudor.actividad ? codeudor.actividad.value : null,
      Antiguedad: codeudor.antiguedad,
      RubroEmpleoPublico: codeudor.rubro ? codeudor.rubro.value : null,
      Provincia: codeudor.direccion.selectedProvince.value,
      Localidad: codeudor.direccion.selectedCity.value,
      CodigoPostal: codeudor.direccion.selectedPostCode.value,
      Direccion: codeudor.direccion.streetAddress,
      Telefonos: codeudor.contacto.phones
        .filter(t => t.numero && t.numero.length > 0)
        .map(t => ({
          Numero: t.numero,
          TipoTelefono: t.tipoContacto,
          EsMovil: t.esMovil,
          HorarioContacto: t.horarioContacto,
          CorrespondeA: t.nombreContacto
        }))
    };
  } else {
    return null;
  }
};

const constructConyugeData = (estadoCivil, conyuge) => {
  if (estadoCivil === "Casado" && conyuge) {
    return {
      NombreConyuge: conyuge.nombreConyuge,
      ApellidoConyuge: conyuge.apellidoConyuge,
      DocumentoConyuge: conyuge.documentoConyuge,
      SexoConyuge: conyuge.sexoConyuge.value,
      ActividadDeclarada: conyuge.actividadDeclarada
        ? conyuge.actividadDeclarada.value
        : null,
      Antiguedad: conyuge.antiguedad ? conyuge.antiguedad.value : null,
      FechaNacimiento: conyuge.fechaNacimiento,
      Ingreso: conyuge.ingresos,
      Cuil: conyuge.cuil
    };
  } else {
    return null;
  }
};
const constructData = (codeudor, conyuge) => {
  const vCodeudor =
    codeudor && codeudor.complete
      ? {
          Nombre: codeudor.nombres + " " + codeudor.apellidos,
          Documento: codeudor.dni,
          ActividadDeclarada: codeudor.actividad && codeudor.actividad.label,
          FechaNacimiento: codeudor.fechaNacimiento,
          Ingreso: codeudor.ingresos
        }
      : null;
  const vConyuge =
    conyuge && conyuge.complete
      ? {
          Nombre: conyuge.nombreConyuge + " " + conyuge.apellidoConyuge,
          Documento: conyuge.documentoConyuge,
          ActividadDeclarada:
            conyuge.actividadDeclarada && conyuge.actividadDeclarada.label,
          FechaNacimiento: conyuge.fechaNacimiento,
          Ingreso: conyuge.ingresos
        }
      : null;
  if (vCodeudor && vConyuge)
    return JSON.stringify({
      codeudor: vCodeudor,
      conyuge: vConyuge
    });
  if (vCodeudor && !vConyuge)
    return JSON.stringify({
      codeudor: vCodeudor
    });
  return null;
};
