/* eslint-disable react-hooks/exhaustive-deps */
import {
  CloudWatchLogsClient,
  CreateLogStreamCommand,
  DescribeLogStreamsCommand,
  InputLogEvent,
  PutLogEventsCommand,
  PutLogEventsCommandInput,
} from "@aws-sdk/client-cloudwatch-logs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import environment from "environment";
import { LoggerContext } from "./logger.context";
import { useAuth0Context } from "componentes/Auth0/AuthContext";
import { format } from "date-fns";
import { ILogData } from "./logger.types";
import { useFlags } from "launchdarkly-react-client-sdk";
import { addMiddleware, resetMiddlewares } from "redux-dynamic-middlewares";

enum CloudWatchExceptions {
  invalidSequenceToken = "InvalidSequenceTokenException",
}

const client = new CloudWatchLogsClient({
  region: "us-east-1",
  credentials: {
    accessKeyId: environment.awsLogs.apiKey!,
    secretAccessKey: environment.awsLogs.apiSecret!,
  },
});

const logQueue = new Array<InputLogEvent>();

const enqueueLogEvent = ({ message, payload }: ILogData) => {
  logQueue.push({
    message: message + " - " + JSON.stringify(payload),
    timestamp: Date.now(),
  });
};

export const LogsProvider = ({ children }: any) => {
  const { authState }: any = useAuth0Context(); // TODO: provide a type for Auth0 Context
  const { debugAppWeb, infoAppWeb } = useFlags();

  const [logStreamName, setLogStreamName] = useState<string>();
  const [sequenceToken, setSequenceToken] = useState<string | null>();

  useEffect(() => {
    const logStreamPreffix = authState?.user?.email
      ? `${authState.user.email}-`
      : "";
    const newLogStreamName = `${logStreamPreffix}${format(
      new Date(),
      "yyyy/MM/dd"
    )}`;
    newLogStreamName !== logStreamName && setLogStreamName(newLogStreamName);
  }, [authState.user]);

  const createLogStream = useCallback(() => {
    const createLogStreamCommand = new CreateLogStreamCommand({
      logGroupName: environment.awsLogs.logGroup!,
      logStreamName: logStreamName,
    });

    client
      .send(createLogStreamCommand)
      .then(() => {
        setSequenceToken(null);
      })
      .catch((error) => console.error(error));
  }, [logStreamName]);

  // Check the log stream.
  useEffect(() => {
    if (!logStreamName) return;

    const describeLogStreamCommand = new DescribeLogStreamsCommand({
      logGroupName: environment.awsLogs.logGroup!,
      logStreamNamePrefix: logStreamName,
    });

    client
      .send(describeLogStreamCommand)
      .then((data) => {
        const logStream = (data.logStreams || [])[0];
        if (logStream) {
          // Setting the sequence token to null in order to indicate that the log stream is empty.
          setSequenceToken(
            logStream.uploadSequenceToken === undefined
              ? null
              : logStream.uploadSequenceToken
          );
        } else createLogStream();
      })
      .catch((error) => console.error(error));
  }, [logStreamName]);

  useEffect(() => {
    const isLoggerReady =
      client &&
      logStreamName &&
      sequenceToken !== undefined &&
      logQueue.length > 0;

    if (!isLoggerReady) return;

    const logParams: PutLogEventsCommandInput = {
      logEvents: [...logQueue],
      logGroupName: environment.awsLogs.logGroup!,
      logStreamName: logStreamName,
      // If log stream is empty, there's no need to provide the sequence token
      ...(sequenceToken && { sequenceToken }),
    };

    const command = new PutLogEventsCommand(logParams);

    client
      .send(command)
      .then((data) => {
        setSequenceToken(data?.nextSequenceToken);
        // Empty log queue
        logQueue.splice(0, logQueue.length);
      })
      .catch((error) => {
        error.name === CloudWatchExceptions.invalidSequenceToken &&
          setSequenceToken(error.expectedSequenceToken);
        console.log({ error });
      });
  }, [logQueue.length, logStreamName, sequenceToken]);

  const logger = useMemo(
    () => ({
      info(log: string, payload: Object) {
        if (!infoAppWeb) return;

        const logPreffix = authState.user
          ? `[INFO] [${authState.user.euroUserId}]`
          : "[INFO]";
        enqueueLogEvent({
          message: `${logPreffix} ${log}`,
          payload: { ...payload, user: authState.user },
        });

        environment.name !== "production" && console.dir(log, payload);
      },
      error(log: string, payload: Object) {
        const logPreffix = authState.user
          ? `[ERROR] [${authState.user.euroUserId}]`
          : "[ERROR]";
        enqueueLogEvent({
          message: `${logPreffix} ${log}`,
          payload: { ...payload, user: authState.user },
        });

        // Log to browser console.
        console.error(payload);
      },
      debug(log: string, payload: Object) {
        if (!debugAppWeb) return;

        const logPreffix = authState.user
          ? `[DEBUG] [${authState.user.euroUserId}]`
          : "[DEBUG]";
        enqueueLogEvent({
          message: `${logPreffix} ${log}`,
          payload: { ...payload, user: authState.user },
        });

        environment.name !== "production" && console.dir(log, payload);
      },
    }),
    [authState, debugAppWeb, infoAppWeb]
  );

  useEffect(() => {
    // Remove previous middlewares.
    // This is needed because the user logs in and logger funcions are regenerated.
    resetMiddlewares();

    // Adding the logger to the store as a middleware in here because it's not possible
    // to call this context when the store is created.
    const loggerMiddleware = (store: any) => (next: any) => (action: any) => {
      const isError =
        action.type.toLowerCase().includes("fail") ||
        action.type.toLowerCase().includes("error");

      if (isError) {
        logger.error(action.type, action.payload);
      } else logger.debug(action.type, action.payload);

      return next(action);
    };
    addMiddleware(loggerMiddleware);
  }, [authState, debugAppWeb]);

  return (
    <LoggerContext.Provider value={{ logger }}>
      {children}
    </LoggerContext.Provider>
  );
};
