import React from "react";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import waveBackground from "images/auth/wave-bg.svg";
import waveBackgroundPrendo from "images/auth/wave-prendo.svg";
import waveBackgroundMobile from "images/auth/wave-mobile.svg";
import waveBackgroundMobilePrendo from "images/auth/wave-mobile-prendo.svg";
import logo from "images/auth/logo-mg-white.svg";
import logoPrendo from "images/auth/prendo-isologotipo-negativo-doscolores-by-mg.svg";
import InfiniteScroll from "componentes/shared/layout/InfiniteScroll";
import { useFlags } from "launchdarkly-react-client-sdk";
import environment from "environment";
import pxToRem from "libs/pxToRem";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = (isRebrandingEnabled) =>
  makeStyles((theme) => ({
    headerContainer: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    wave: {
      position: "absolute",
      width: "100%",
      minHeight: "100vh",
      margin: 0,
      backgroundRepeat: "no-repeat",
      padding: 0,
      backgroundSize: "100%",
      backgroundPosition: "0 top",
      [theme.breakpoints.up("lg")]: {
        backgroundPosition: "top",
        backgroundSize: "100%",
      },
      "@media (max-width:446px)": {
        backgroundSize: "100vw 30vh",
      },
    },
    wavePrendo: {
      "@media (max-width:446px)": {
        backgroundImage: `url(${waveBackgroundMobilePrendo})`,
      },
      "@media (min-width:447px)": {
        backgroundImage: `url(${waveBackgroundPrendo})`,
      },
    },
    waveMG: {
      "@media (max-width:446px)": {
        backgroundImage: `url(${waveBackgroundMobile})`,
      },
      "@media (min-width:447px)": {
        backgroundImage: `url(${waveBackground})`,
      },
    },
    header: {
      zIndex: 1,
      display: "flex",
      textAlign: "left",
      maxWidth: "900px",
      flexDirection: "column",
      padding: "7vh 4vw",
      paddingLeft: "2vw",
      "@media (max-width:1024px)": {
        display: "none",
      },
    },
    brandIcon: {
      objectFit: "contain",
      alignSelf: "flex-start",
      height: "3em",
    },
    titleHeader: {
      zIndex: 1,
      display: "flex",
      color: "#ffffff",
      fontWeight: "bold",
      fontSize: "2.5rem",
      lineHeight: "1.2",
      maxWidth: "35rem",
      marginTop: 30,
    },
    subtitleHeader: {
      zIndex: 1,
      color: "#ffffff",
      fontSize: "1.3rem",
      lineHeight: "1.2",
      maxWidth: "26rem",
      marginTop: 30,
    },
    loginBox: {
      zIndex: 1,
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      width: "28rem",
      justifyContent: "center",
      alignItems: "center",
      maxWidth: "90vw",
      objectFit: "contain",
      paddingBottom: 10,
      paddingTop: "4em",
      "@media (max-width:1024px)": {
        paddingTop: 44,
      },
    },
    brandIconMobile: {
      height: 60,
      marginBottom: 44,
      "@media (min-width:1024px)": {
        display: "none",
      },
    },
    card: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 40,
      borderRadius: 16,
      width: "90%",
      boxShadow: "0 0 20px 0 #663ea836",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    logoEnvironmentMark: {
      zIndex: 1,
      display: "flex",
      fontWeight: "bold",
      fontSize: pxToRem(18),
      color: "#5fc7d7",
      margin: isRebrandingEnabled ? "-37px 0 0 170px" : "-25px 0 0 100px",
    },
    logoEnvironmentMarkMobile: {
      zIndex: 1,
      display: "flex",
      fontWeight: "bold",
      fontSize: pxToRem(18),
      color: "#5fc7d7",
      margin: isRebrandingEnabled ? "-37px 0 0 170px" : "-68px 0 40px 158px",
    },
  }));

const Layout = ({ children }) => {
  const flags = useFlags();
  const isRebrandingEnabled = flags["rebranding"];
  const classes = useStyles(isRebrandingEnabled)();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.mobile);

  return (
    <div style={{ background: "#f0f2ff", minHeight: "100vh" }}>
      <InfiniteScroll />
      <div className={classes.headerContainer}>
        <div
          className={`${classes.wave} ${
            isRebrandingEnabled ? classes.wavePrendo : classes.waveMG
          }`}
        />
        <div style={{ display: "flex" }}>
          <div className={classes.header}>
            <img
              src={isRebrandingEnabled ? logoPrendo : logo}
              className={classes.brandIcon}
              alt=""
            />
            {environment.name !== "production" && !isMobile && (
              <Typography className={classes.logoEnvironmentMark}>
                {"TEST"}
              </Typography>
            )}
            <Typography variant={"h3"} className={classes.titleHeader}>
              {"¡Hola! :)"}
            </Typography>
            <Typography variant={"h5"} className={classes.subtitleHeader}>
              {
                "Desde esta plataforma vas a poder realizar todas las gestiones de tus créditos y seguros de manera online, todos los días, en cualquier momento."
              }
            </Typography>
          </div>
          <div className={classes.loginBox}>
            <img
              src={isRebrandingEnabled ? logoPrendo : logo}
              className={classes.brandIconMobile}
              alt=""
            />
            {environment.name !== "production" && isMobile && (
              <Typography className={classes.logoEnvironmentMarkMobile}>
                {"TEST"}
              </Typography>
            )}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Layout;
