import React from "react";
import { useFlags } from 'launchdarkly-react-client-sdk';

const FeatureFlagRender = ({ flag, when = true, children }) => {
  const flags = useFlags();
  const value = flags[flag];

  if (when !== value) return null;

  return <>{children}</>;
};

export default FeatureFlagRender;
