import React, { createContext, useState, useContext, useEffect } from "react";

import { WebAuth } from "auth0-js";
import environment from "../../environment";

import { authStateSync } from "componentes/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import localRepository from "../../localRepository";

const generateAuth = () =>
  new WebAuth({
    domain: environment.auth0.domain,
    clientID: environment.auth0.clientId,
    redirectUri: `${window.location.origin}/callback`,
    responseType: "token id_token",
    scope: "openid profile email offline_access",
    audience: `http://mg-group.com.ar/apis`,
    prompt: "login",
  });

const Auth0Context = createContext(null);

const useReduxSelector = () =>
  useSelector((state) => ({
    validatedIdentity: state.nativeMessagesReducer.validatedIdentity,
  }));

const useAuthState = () => {
  const initialState = {
    accessToken: localRepository.accessToken.get(),
    idToken: null,
    expiresAt: 0,
    user: null,
  };
  const { validatedIdentity } = useReduxSelector();
  const [authState, setAuthState] = useState(initialState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authStateSync(authState));
  }, [dispatch, authState]);

  useEffect(() => {
    if (validatedIdentity && authState.user) {
      let newState = { ...authState };
      newState.user.validatedIdentity = true;
      setAuthState(newState);
    }
  }, [validatedIdentity]);

  return [authState, setAuthState];
};

const useContextValue = () => {
  const [authState, updateAuthState] = useAuthState();
  return {
    auth0: generateAuth(),
    authState,
    updateAuthState,
  };
};

export const Auth0Provider = ({ children }) => {
  const value = useContextValue();
  return (
    <Auth0Context.Provider value={value}>{children}</Auth0Context.Provider>
  );
};

export const useAuth0Context = () => {
  return useContext(Auth0Context);
};
