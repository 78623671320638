import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import App from "./App";

const mapStateToProps = state => {
  return {
    isOnNativeApp: state.nativeMessagesReducer.isOnNativeApp,
    nativeCredentials: state.nativeMessagesReducer.nativeCredentials
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

export default AppContainer;
