import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { onNativeMessageReceived } from "./js/actions";
import NativeAppMessaging from "./NativeAppMessaging";
import { logOutUser } from "../user/js/actions";

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onNativeMessageReceived,
      logOutUser,
    },
    dispatch
  );
};

const NativeAppMessagingContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NativeAppMessaging);

export default NativeAppMessagingContainer;
