let createPostSignupClienteFinal = ({
  fetch,
  environment,
  buildRequestWithAuth,
  errorUnlessOk
}) => formData => {
  let request = buildRequestWithAuth("POST", formData);

  return fetch(
    `${environment.authUrl}/controldeusuarios/registrousuariofinal`,
    request
  )
    .then(errorUnlessOk)
    .then(response => {
      return response;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};

export { createPostSignupClienteFinal };
