let createGetCalculadorConsumoResult =
  ({ fetch, environment, buildRequestWithAuth, errorUnlessOk }) =>
  (data) => {
    let request = buildRequestWithAuth("GET", data);

    return fetch(
      `${environment.originacionUrl}/calculador/consumo?LineaProducto=${data.LineaProducto}&importe=${data.Importe}`,
      request
    )
      .then(errorUnlessOk)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };

export { createGetCalculadorConsumoResult };
