import { createContext, useContext } from "react";

interface LogsContextType {
  logger: {
    info(log: string, payload: Object): void;
    error(log: string, payload: Object): void;
    debug(log: string, payload: Object): void;
  };
}

export const LoggerContext = createContext<LogsContextType>({
  logger: {
    info() {},
    error() {},
    debug() {},
  },
});

export const useLoggerContext = () => {
  return useContext(LoggerContext);
};
