import {
  RN_IS_ON_NATIVE_APP,
  RN_DEVICE_INFO,
  RN_SCAN_CEDULA,
  CLEAR_NATIVE_CEDULA,
  RN_SCAN_DNI,
  RN_NATIVE_CREDENTIALS,
  RN_IDENTIDAD_VALIDADA,
  RN_DEEP_LINK_TAPPED,
} from "./actions";
import localRepository from "../../../localRepository";

const initialState = {
  isOnNativeApp: localRepository.isOnNativeApp.get(),
  deviceInfo: null,
  dominio: null,
  motor: null,
  chasis: null,
  nombre: null,
  apellido: null,
  sexo: null,
  dni: null,
  fechaNacimiento: null,
  nativeCredentials: null,
  validatedIdentity: null,
  deepLinkData: null,
};

const nativeMessagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case RN_IS_ON_NATIVE_APP:
      localRepository.isOnNativeApp.set(true);
      return {
        ...state,
        isOnNativeApp: true,
      };
    case RN_DEVICE_INFO:
      localRepository.deviceInfo.set(action.payload.data);
      return {
        ...state,
        deviceInfo: action.payload.data,
      };
    case RN_SCAN_CEDULA:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_NATIVE_CEDULA:
      return {
        ...state,
        dominio: null,
        motor: null,
        chasis: null,
      };
    case RN_SCAN_DNI:
      return {
        ...state,
        ...action.payload,
      };
    case RN_NATIVE_CREDENTIALS:
      return {
        ...state,
        nativeCredentials: action.payload.credentials,
      };
    case RN_IDENTIDAD_VALIDADA:
      return {
        ...state,
        validatedIdentity: true,
      };
    case RN_DEEP_LINK_TAPPED:
      return {
        ...state,
        deepLinkData: action.payload.data,
      };
    default:
      return state;
  }
};

export default nativeMessagesReducer;
